import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    userRoleValue = new Subject();
    userIdValue = new Subject();

    set userRole(value) {
        this.userRoleValue.next(value); // this will make sure to tell every subscriber about the change.
        localStorage.setItem('userRole', value);
    }

    get userRole() {
        return localStorage.getItem('userRole');
    }

    set userId(value) {
        this.userRoleValue.next(value); // this will make sure to tell every subscriber about the change.
        localStorage.setItem('uid', value);
    }

    get userId() {
        return localStorage.getItem('uid');
    }
}