import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutsModule } from './layouts';
import { CommonLayoutComponent } from './layouts/common-layout';
import { ChartsComponent } from './pages/charts';
import { ComponentsComponent } from './pages/components';
import { DashboardComponent } from './pages/dashboard';
import { Dashboard2Component } from './pages/dashboard2';
import { FormsComponent } from './pages/forms';
import { PlacesComponent } from './pages/places/places.component'
import { AuthGuard } from './connect/auth/auth-guard.service';
// import { SettingsComponent } from './settings/settings.component';
import { StockComponent } from './stock/stock.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UserFormComponent } from './manage-users/user-form';
import { DoctorsComponent } from './doctors/doctors.component';
import { ScheduleComponent } from './schedule/schedule.component';

import { HomeComponent } from './home/home.component';
import { UrgenteComponent } from './home/urgente/urgente.component';
import { ProfilaxieComponent } from './home/profilaxie/profilaxie.component';
import { ProteticaComponent } from './home/protetica/protetica.component';
import { EndodontieComponent } from './home/endodontie/endodontie.component';
import { OdontoterapieComponent } from './home/odontoterapie/odontoterapie.component';
import { OrtodontieComponent } from './home/ortodontie/ortodontie.component';
import { PedodontieComponent } from './home/pedodontie/pedodontie.component';
import { ChirurgieComponent } from './home/chirurgie/chirurgie.component';

import { PricesComponent } from './home/prices/prices.component';
import { EchipamenteComponent } from './echipamente/echipamente.component';
import { EditEchipamentComponent } from './echipamente/edit-echipament/edit-echipament.component';
import { ScheduleListComponent } from './schedule/schedule-list/schedule-list.component';
import { PatientsComponent } from './patients/patients.component';
import { PatientComponent } from './patients/patient/patient.component';
import { RecordsComponent } from './manage-users/user-form/records/records.component';
import { StatisticsComponent } from './pages/charts/statistics/statistics.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { ArticleComponent } from './home/articles/article/article.component';
import { AddEchipamentComponent } from './echipamente/add-echipament/add-echipament.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { AccountComponent } from './settings/account/account.component';


@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'app/schedule', pathMatch: 'full' },
        {
          path: 'app', component: CommonLayoutComponent, children: [
            // { path: 'home',  loadChildren: './home/home.module#HomeModule', pathMatch: 'full' },
            // { path: 'home', loadChildren: './home/home.module#HomeModule' },
            { path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },
            { path: 'dashboard-custom', component: Dashboard2Component, pathMatch: 'full' },
            { path: 'forms', component: FormsComponent, pathMatch: 'full' },
            { path: 'charts', component: ChartsComponent, pathMatch: 'full' },
            { path: 'statistics', component: StatisticsComponent, pathMatch: 'full' },
            { path: 'components', component: ComponentsComponent, pathMatch: 'full' },
            { path: 'places', component: PlacesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            // { path: 'settings', component: SettingsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            { path: 'stock', component: StockComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            { path: 'echipamente', component: EchipamenteComponent, pathMatch: 'full' },
            { path: 'echipamente/:id', component: EditEchipamentComponent, pathMatch: 'full' },
            { path: 'product_add', component: AddEchipamentComponent, pathMatch: 'full' },
            { path: 'users', component: ManageUsersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            { path: 'user/:id', component: UserFormComponent, pathMatch: 'full' },
            { path: 'doctors', component: DoctorsComponent, pathMatch: 'full' },
            { path: 'patients', component: PatientsComponent, pathMatch: 'full' },
            // { path: 'patients/:id', component: PatientComponent, pathMatch: 'full' },
            { path: 'patient', component: PatientComponent, pathMatch: 'full' },
            { path: 'gdpr', component: GdprComponent, pathMatch: 'full' },

            { path: 'records', component: RecordsComponent, pathMatch: 'full' },

            { path: 'home', component: HomeComponent, pathMatch: 'full' },
            { path: 'article', component: ArticleComponent, pathMatch: 'full' },
            { path: 'home/urgente', component: UrgenteComponent, pathMatch: 'full' },
            { path: 'home/profilaxie', component: ProfilaxieComponent, pathMatch: 'full' },
            { path: 'home/protetica', component: ProteticaComponent, pathMatch: 'full' },
            { path: 'home/endodontie', component: EndodontieComponent, pathMatch: 'full' },
            { path: 'home/odontoterapie', component: OdontoterapieComponent, pathMatch: 'full' },
            { path: 'home/ortodontie', component: OrtodontieComponent, pathMatch: 'full' },
            { path: 'home/pedodontie', component: PedodontieComponent, pathMatch: 'full' },
            { path: 'home/chirurgie', component: ChirurgieComponent, pathMatch: 'full' },
            { path: 'home/prices', component: PricesComponent, pathMatch: 'full' },

            { path: 'schedule', component: ScheduleComponent, pathMatch: 'full' },
            { path: 'schedule/:id', component: ScheduleComponent, pathMatch: 'full' },
            { path: 'schedules', component: ScheduleListComponent, pathMatch: 'full' },
            { path: 'schedules/:id', component: ScheduleListComponent, pathMatch: 'full' },
            { path: '**', redirectTo: '/pages/404' },
          ]
        }, // add 'canActivate: AuthGuard' for catching unauth users

        // { path: 'home', component: HomeComponent, pathMatch: 'full' },
        { path: 'ui', loadChildren: './pages/ui/ui.module#UIModule' },
        { path: 'maps', loadChildren: './pages/maps/maps.module#MapsModule' },
        { path: 'pages', loadChildren: './pages/pages/pages.module#PagesModule' },
        // { path: 'home', component: CommonLayoutComponent, loadChildren: './home/home.module#HomeModule' },

        // { path: 'settings', loadChildren: './settings/settings.module#SettingsModule', canActivate: [AuthGuard] },
        { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },

        // {
        //   path: 'settings', component: CommonLayoutComponent, canActivate: [AuthGuard],

        //   children: [
        //     // { path: '', redirectTo: 'profile', pathMatch: 'full' },
        //     { path: 'profile', component: ProfileComponent, pathMatch: 'full' },
        //     { path: 'account', component: AccountComponent, pathMatch: 'full' }
        //   ]
        // },

        { path: '**', redirectTo: '/pages/404' },
      ],
      { useHash: true },
    ),
    LayoutsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
