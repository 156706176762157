import { Injectable, OnInit } from '@angular/core';
import { DatabaseCollection, DatabaseService } from 'app/connect';
import { dbEvent } from 'app/schedule/schedule.model';
import { Observable, } from 'rxjs';
import { firestore, auth } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { map } from 'rxjs/operators';
import { dbPatient } from './patient.model';


@Injectable()
export class PatientsService extends DatabaseCollection<dbPatient> implements OnInit {

    constructor(
        db: DatabaseService,
    ) {
        super(db, db.col('patients'));

    }
    ngOnInit(): void {
        //https://github.com/mounthorse-slns/angular-calendar-scheduler/blob/master/src/app/services/app.service.ts
    }

    getPatients(): Observable<dbPatient[]> {
        return this.stream(ref => ref.orderBy('name', 'asc')).pipe(map((res: any) => {
            // res.forEach((e, index) => {
            //     e.start = (<Timestamp>e.start).toDate();
            //     e.end = (<Timestamp>e.end).toDate();
            //     e.actions = actions;
            //     e.index = index;
            // })
            return res;
        }))

        // return new Promise(resolve => setTimeout(() => resolve(events), 3000));
    }

}