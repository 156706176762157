import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';

import { UpgradableComponent } from 'theme/components/upgradable';
import { StockService } from './stock.service';
import { DatabaseService, dbCommon, DatabaseCollection, } from 'app/connect';
import { defineBase } from '@angular/core/src/render3';
import { removeSummaryDuplicates } from '@angular/compiler';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY, MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { dbProduct } from 'app/echipamente/echipament/echipament.component';
import { ConfirmationDialog } from 'app/dialogs/confirmation-dialog/confirmation-dialog.component';
//import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})
export class StockComponent extends /*UpgradableComponent,*/ DatabaseCollection<dbProduct> implements OnInit {
  public readonly Array = Array;

  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-cell') private readonly mdlCell = true;
  @HostBinding('class.mdl-cell--12-col-desktop') private readonly mdlCell12ColDesktop = true;
  @HostBinding('class.mdl-cell--12-col-tablet') private readonly mdlCell12ColTablet = true;
  @HostBinding('class.mdl-cell--4-col-phone') private readonly mdlCell4ColPhone = true;
  @HostBinding('class.mdl-cell--top') private readonly mdlCellTop = true;
  @HostBinding('class.ui-tables') private readonly uiTables = true;

  private products$: Observable<dbProduct[]>;

  get role(): string { return localStorage.getItem('userRole') };

  // @ViewChild('dialogModal') dialogModal: ModalDirective;
  isModalShown = false;

  public constructor(
    private stockService: StockService, db: DatabaseService, private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar) {

    super(db, db.col('stock'));
    this.products$ = this.stream(ref => ref.orderBy('name', 'asc'));
  }

  ngOnInit() {


    //this.places$ = this.stream( ref => ref.orderBy('name', 'asc') );

    // this.products$ = this.db.collection('stock').get().then(result => {

    //   let i = 0;
    //   result.forEach((item, index) => {
    //     (<any>item).index = index + 1;
    //   });
    //   console.log(result);
    //   return result;
    // })
  }

  public readonly sortOrder = {
    asc: 1,
    desc: -1,
  };

  // public headers = this.stockService.getHeaders();
  // public simpleTable = this.stockService.getSimpleTable();
  // public borderedTable = this.stockService.getBorderedTable();
  // public strippedTable = this.stockService.getStrippedTable();
  // public borderlessTable = this.stockService.getBorderlessTable();
  public advancedHeaders = this.stockService.getAdvancedHeaders();
  public genreColors = {
    'ANESTEZIE': 'orange',
    'AMPRENTARE': 'purple',
    'PROTECTIE SI DEZINFECTIE': 'dark-gray',
    'CHIRURGIE': 'green',
    'ECHIPAMENT': 'teal',
    'Fantasy': 'red',
    'Adventure': 'light-blue',
  };

  public currentPage = 1;
  private countPerPage = 4;
  public numPage = this.stockService.getAdvancedTableNumOfPage(this.countPerPage);

  public advancedTable = this.stockService.getAdvancedTablePage(1, this.countPerPage);

  // public advancedTable = this.stream().pipe().subscribe(res => {
  //   console.log('products', res);
  //   return res;
  // });

  public changePage(page, force = false) {
    if (page !== this.currentPage || force) {
      this.currentPage = page;
      //this.advancedTable = this.stockService.getAdvancedTablePage(page, this.countPerPage);
    }
  }

  /* available sort value:
	-1 - desc; 	0 - no sorting; 1 - asc; null - disabled */
  public changeSorting(header, index) {
    const current = header.sort;
    if (current !== null) {
      this.advancedHeaders.forEach((cell) => {
        cell.sort = (cell.sort !== null) ? 0 : null;
      });
      header.sort = (current === 1) ? -1 : 1;
      this.stockService.changeAdvanceSorting(header.sort, index);
      this.changePage(1, true);
    }
  }

  showModal(): void {
    this.isModalShown = true;
  }

  // hideModal(): void {
  //   this.dialogModal.hide();
  // }

  onHidden(): void {
    this.isModalShown = false;
  }

  deleteItem(product: dbProduct) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: `Sunteti sigur?`,
        buttonText: {
          ok: 'Accept',
          cancel: 'Nu'
        }
      }
    });

    const snack = this.snackBar.open(`Eliminare produsul: ${product.name}`);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        snack.dismiss();
        const a = document.createElement('a');
        a.click();
        a.remove();

        this.document(product.id).delete().then(res => {
          console.log('removed', product);
          snack.dismiss();
          this.snackBar.open('Produsul a fost eliminat', product.name, {
            duration: 2000,
          });
        }).catch(error => console.error("Error removing document: ", error))


        // snack.dismiss();
        // this.snackBar.open('Closing snack bar in a few seconds', 'Fechar', {
        //   duration: 2000,
        // });
      }
      snack.dismiss();
    });
  }

  public save(row: any) {
    const p = <dbProduct>{
      name: row[1],
      supplier: row[2],
      category: row[3],
      price: row[4],
      url: row[5],
      availability: row[6]
    }
    // this.db.collection("stock").add(p).then(result => {
    //   console.log('save result', result);
    // });
    this.db.col("stock").add(p).then(result => {
      console.log('saved product', result.id);
    });
  }

  startEdit(row: any) {
    console.log('product', row);
    localStorage.setItem('editingProductId', row.id)
    this.router.navigate([`/app/echipamente/${row.id}`]);
  }

  createStock() {
    const p = <unknown>{
      "name": "",
      "availability": 4,
      "category": "",
      "quantity": 1
    }

    this.add(<dbProduct>p).then(docRef => {
      console.log("'product created: ", docRef.ref.id);
      localStorage.setItem('editingProductId', docRef.ref.id)
      this.router.navigate([`/app/echipamente/${docRef.ref.id}`]);
    })
      .catch(error => console.error("Error adding document: ", error))
  }

}

// export interface dbProduct extends dbCommon {
//   name: string;
//   supplier: string;
//   category: string;//string[];
//   price: string;
//   url: string;
//   availability: string;
//   display: boolean;
// }
