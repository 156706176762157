import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './auth.service';
// import { AuthHandler } from './auth-handler.component';

@NgModule({
  imports: [
    CommonModule,
    AngularFireAuthModule,
  ],
  // declarations:[AuthHandler],
  // exports:[AuthHandler],
  providers: [AuthService]
})
export class AuthModule { }