import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { AngularFire, FirebaseListObservable,  } from 'angularfire2';
// import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

// import { AngularFirestoreModule } from 'angularfire2/firestore';
// import { ProductService } from '../services/product.service';

// import { map } from 'rxjs/map';

import { Observable } from 'rxjs';

import { ImageCompressService, ResizeOptions, ImageUtilityService } from 'ng2-image-compress';
import { DatabaseCollection, DatabaseDocument, DatabaseService, StorageService, UploadTask } from 'app/connect';
import { dbUser, UserProfile } from 'app/connect/auth/user-profile.service';
import { map } from 'rxjs/operators';


interface Image {
  path: string;
  filename: string;
  downloadURL?: string;
  $key?: string;
}

@Component({
  selector: 'image-upload',
  template: `
  
  <form ngNoForm>
  
  <button mat-mini-fab type="button" for="uploadPicture" class="upload-file"  onclick="document.getElementById('uploadPicture').click()">
   <mat-icon>add_a_photo</mat-icon>
  </button>
  <input type="file"
       id="uploadPicture"
       class="hidden-input"
       accept="image/jpeg, .jpeg, image/png, .png, image/pjpeg, .jpg"
       style="display:none;"
       (change)="uploadPhoto($event.target?.files?.item(0))" #openfile>

  </form>
  <div style="overflow:hidden;">
        <div *ngIf="(photo$ | async) as photo; else avatar" style="position:relative;width:50px;height:50px;float:left;display:flex;justify-content:center;align-items:center;">
            <img [src]="photo" style="max-width:100px;max-height:100px;">
            <button (click)="deletePhoto()" style="position:absolute;top:2px;right:2px;">[x]</button>
        </div>
  </div>
  `,
})
export class UploadComponent extends DatabaseDocument<dbUser> {
  /**
   * The name of the folder for images
   * eg. posts/angular-is-awesome
   */
  @Input() folder: string;
  @Input() photo: string;

  // fileList: AngularFireList<Image>;
  imageList: Observable<Image[]>;
  image: any;
  user$: dbUser;
  readonly photo$: Observable<string>;

  constructor(
    // public af: AngularFireDatabase,
    db: DatabaseService,
    private profile: UserProfile,
    private storage: StorageService,
    public router: Router,
    // private productService: ProductService,
    private imgCompressService: ImageCompressService) {

    // super(db, db.doc(`users/${'ryUCGn1t60SWWkWeNnToWfPFGm32'}`));
    super(db, db.doc(`users/${localStorage.getItem('editingUserId')}`));

    // Streams the profile photo
    this.photo$ = this.profile.stream().pipe(map(profile => !!profile ? profile.photo : ''));
  }


  public uploadTask: UploadTask;

  public uploadPhoto(file: File): Promise<void> {

    if (!file) { return Promise.resolve(null); }

    // Creates the uploading task, this will display the progress bar in the view
    return (this.uploadTask = this.storage.upload(`${this.user$.id}/${file.name}`, file))
      // Returns the url
      .then(snap => snap.ref.getDownloadURL())
      // Updates the profile with the new url
      .then(photo => this.profile.update({ photo }))
      // Deletes the task object removing the progress bar from the view
      .then(() => (delete this.uploadTask, null));
  }

  public deletePhoto(): Promise<void> {

    // Deletes the file in the storage first
    return this.deleteFile()
      // Resets the photo url into the profile
      .then(() => this.profile.update({ photo: '' }));
  }

  private deleteFile(): Promise<void> {
    // Reads the profile to get the photo url
    return this.profile.get().then(profile => {
      // Skips then no file
      if (!profile || !profile.photo) { return null; }
      // Gets the storage ref from the url...
      const ref = this.storage.refFromURL(profile.photo);
      //... and deletes the file
      return ref.delete();
    })
      // Ensure to proceed whatever error has been encountered
      .catch(e => null);
  }



}