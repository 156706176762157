import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ThemeModule } from 'theme';
import { PatientsComponent } from './patients.component';
import { PatientComponent } from './patient/patient.component';
import { PatientsService } from './patients.service';
import {
  MatDialogModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule
} from '@angular/material';
import { MaterialAngularSelectModule } from 'material-angular-select';
import { FormsModule as NgFormsModule } from '@angular/forms';
import { SearchBoxComponent } from 'app/controls/search-box/search-box.component';

// Define navigation routes
// const routes: Routes = [

//   { path: '', component: CommonLayoutComponent }

// ];

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    NgFormsModule,
    FlexLayoutModule,
    MaterialAngularSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    ThemeModule,
    RouterModule,

    // RouterModule.forChild(routes),
  ],
  declarations: [
    PatientsComponent,
    PatientComponent,
    SearchBoxComponent,
  ],
  exports: [
    PatientsComponent,
    PatientComponent
  ],
  providers: [
    PatientsService,
    { provide: LOCALE_ID, useValue: 'en-US' }
    // exports: [ RouterModule ]
  ],
})
export class PatientsModule { }