import { Component, OnInit, OnDestroy } from '@angular/core';
import { dbPatient } from '../patient.model';
import { UserProfile } from 'app/connect/auth/user-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, DatabaseService, StorageService, DatabaseDocument } from 'app/connect';
import { FormBuilder } from '@angular/forms';
import { loremIpsum } from 'lorem-ipsum';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent extends DatabaseDocument<dbPatient> implements OnInit, OnDestroy {

  user$: dbPatient = null;

  get isNewPatient(): boolean { return localStorage.getItem('isNewPatient') == 'true' };

  constructor(db: DatabaseService,
    private auth: AuthService,
    // private router: Router,
    // public dialog: MatDialog
    private route: ActivatedRoute,
    private router: Router,
    private profile: UserProfile, private storage: StorageService, private builder: FormBuilder) {

    super(db, db.doc(`patients/${localStorage.getItem('editingUserId')}`));

    this.stream().pipe().subscribe(response => {
      console.log('user', response);
      this.user$ = response;

      if (!this.user$.antecedente)
        this.user$.antecedente = loremIpsum({
          count: 3,                // Number of "words", "sentences", or "paragraphs"
          format: "plain",         // "plain" or "html"
          paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
          paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
          random: Math.random,     // A PRNG function
          sentenceLowerBound: 5,   // Min. number of words per sentence.
          sentenceUpperBound: 15,  // Max. number of words per sentence.
          suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
          units: "paragraphs",      // paragraph(s), "sentence(s)", or "word(s)"
          // words: ["ad",]       // Array of words to draw from
        });

      if (!this.user$.alergii)
        this.user$.alergii = loremIpsum({
          count: 3,                // Number of "words", "sentences", or "paragraphs"
          format: "plain",         // "plain" or "html"
          paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
          paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
          random: Math.random,     // A PRNG function
          sentenceLowerBound: 5,   // Min. number of words per sentence.
          sentenceUpperBound: 15,  // Max. number of words per sentence.
          suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
          units: "paragraphs",      // paragraph(s), "sentence(s)", or "word(s)"
          // words: ["ad",]       // Array of words to draw from
        });

      if (!this.user$.tratamente)
        this.user$.tratamente = loremIpsum({
          count: 3,                // Number of "words", "sentences", or "paragraphs"
          format: "plain",         // "plain" or "html"
          paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
          paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
          random: Math.random,     // A PRNG function
          sentenceLowerBound: 5,   // Min. number of words per sentence.
          sentenceUpperBound: 15,  // Max. number of words per sentence.
          suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
          units: "paragraphs",      // paragraph(s), "sentence(s)", or "word(s)"
          // words: ["ad",]       // Array of words to draw from
        });

    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    localStorage.removeItem("editingUserId");
    localStorage.removeItem("isNewPatient");
  }

  saveUser(user) {
    this.update(user).then(u=>{
      this.router.navigate(['/app/patients']);  
    }).catch(error => console.error("Error saving document: ", error));
  }

  cancelCreateNew(user) {
    this.delete().then().catch(error => console.error("Error removing document: ", error));
    this.router.navigate(['/app/patients']);
  }

}
