import { Component, OnInit, OnDestroy, ViewChild, Inject, Renderer, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material';
import { AuthGuard } from './connect/auth/auth-guard.service';
import { UserProfile } from './connect/auth/user-profile.service';
import { Observable, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';

import { FirebaseApp } from '@angular/fire';
import { GlobalService } from './connect/auth/global.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  readonly userName$: Observable<string>;
  readonly userRole$: Observable<string>;
  private sub: Subscription;

  private _router: Subscription;
  // @ViewChild(NavbarComponent, { static: true }) navbar: NavbarComponent;


  readonly footerItems = [
    { icon: "fab:fa-angular", link: "https://angular.io" },
    { icon: "fab:fa-github", link: "https://github.com" },
    { icon: "fab:fa-medium", link: "https://medium.com/wizdm-genesys" }
  ];

  get auth() { return this.profile.auth; }

  get authenticated() { return this.guard.authenticated; }

  constructor(public app: FirebaseApp,
    readonly guard: AuthGuard,
    private profile: UserProfile,
    private renderer: Renderer,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef,
    private globalSrv: GlobalService,
    public location: Location) {

    // Streams the user name from the profile

    this.userName$ = this.profile.stream().pipe(
      map(data => !!data ? data.name : '')
    );

    this.userRole$ = this.profile.stream().pipe(
      map(data => !!data ? data.role : '')
    );

    this.userRole$.subscribe(role => {
      console.log('role', role);
      // localStorage.setItem('userRole', (<any>role).value);
      globalSrv.userRole = (<any>role).value;
    })
  }

  ngOnInit() {

    // var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
    // this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    //   if (window.outerWidth > 991) {
    //     window.document.children[0].scrollTop = 0;
    //   } else {
    //     window.document.activeElement.scrollTop = 0;
    //   }
    //   // this.navbar.sidebarClose();

    //   this.renderer.listenGlobal('window', 'scroll', (event) => {
    //     const number = window.scrollY;
    //     var _location = this.location.path();
    //     _location = _location.split('/')[2];

    //     if (number > 150 || window.pageYOffset > 150) {
    //       navbar.classList.remove('navbar-transparent');
    //     } else if (_location !== 'login' && this.location.path() !== '/nucleoicons') {
    //       // remove logic
    //       navbar.classList.add('navbar-transparent');
    //     }
    //   });
    // });
  }

  ngOnDestroy() { this.sub.unsubscribe(); }

}
