import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialAngularSelectModule } from 'material-angular-select';

import { ThemeModule } from 'theme';

// import { ButtonsComponent } from '../pages/ui/buttons';
// import { CardsComponent } from '../pages/ui/cards';
// import { ColorsComponent } from '../pages/ui/colors';
// import { FormsComponent } from '../pages/ui/forms';
import { StockComponent } from './stock.component';
import { StockService } from './stock.service';

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        MaterialAngularSelectModule,
    ],
    declarations: [
        // ButtonsComponent,
        // CardsComponent,
        // ColorsComponent,
        // FormsComponent,
        StockComponent,
    ],
    providers: [
        StockService,
    ],
})
export class StockModule { }