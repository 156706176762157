import { Component, OnInit } from '@angular/core';
import { DatabaseDocument, DatabaseService, DatabaseCollection } from 'app/connect';
import { dbArticle } from '../articles.component';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent extends DatabaseDocument<dbArticle> implements OnInit {

  article$: Observable<dbArticle>;
  id: any;

  constructor(db: DatabaseService, private route: ActivatedRoute) {
    // Constructs the DatabseColleciton referring to 'articles'
    super(db, db.doc(`home/${localStorage.getItem('article')}`));

    // Streams the array of places ordering them by ascending names 
    this.article$ = this.stream();
  }

  ngOnInit() {

  }


}
