import { Injectable } from '@angular/core';

@Injectable()
export class StockStatisticsChartService {
  public getCountryStatistics() {
    return [
      {
        key: 'Anestezie',
        y: 31,
      },
      {
        key: 'Amprentare',
        y: 26,
      },
      {
        key: 'Protectie si dezinnfectie',
        y: 18,
      },
      {
        key: 'Chirurgie',
        y: 15,
      },
      {
        key: 'Altele',
        y: 10,
      },
    ];
  }
}
