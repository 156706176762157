import { Injectable } from '@angular/core';


@Injectable()
export class StockService {

  private advanceTableData = [
    [
      '5',
      'Ace atraumatice 27G, XXL, Prima (100 buc)',
      'realdent',
      [
        'ANESTEZIE',
      ],
      'RON21.99',
      'https://realdent.ro/index.php/anestezie/ace-atraumat-100b-27g-xxl.html',
      4,
    ],
    [
      '6',
      'Ace atraumatice 30G Prima (100 buc)',
      'realdent',
      [
        'ANESTEZIE',
      ],
      'RON21.99',
      'https://realdent.ro/index.php/anestezie/ace-atraumat-100b-30g.html',
      1,
    ],
    [
      '7',
      'Gel Anestezic Stomatologie Opahl/Lolite 30gr',
      'realdent',
      [
        'ANESTEZIE',
      ],
      'RON59.99',
      'https://realdent.ro/index.php/anestezie/gel-anestezic-stomatologie-opahl-lolite-30gr.html',
      2,
    ],
    [
      '8',
      'Alginat de precizie superioara pentru amprente cu indicator de faza cromatic, 453 grame',
      'realdent',
      [
        'AMPRENTARE',
      ],
      'RON27.99',
      'https://realdent.ro/index.php/amprentare/alginat-cu-indicator-de-faza-cromatic-453-gr.html',
      0,
    ],
    [
      '9',
      'Linguri plastic pentru material de impresie (pereche superior / inferior)',
      'realdent',
      [
        'AMPRENTARE',
      ],
      'RON2.49',
      'https://realdent.ro/index.php/amprentare/linguri-plastic-impresie-pereche.html',
      1,
    ],
    [
      '10',
      'Canule de mixare',
      'realdent',
      [
        'AMPRENTARE',
      ],
      'RON1.99',
      'https://realdent.ro/index.php/amprentare/canule-de-mixare.html',
      3,
    ],
    [
      '11',
      'Varfuri mixare Galbene',
      'realdent',
      [
        'AMPRENTARE',
      ],
      'RON0.30',
      'https://realdent.ro/index.php/amprentare/varfuri-mixare-galbene.html',
      4,
    ],
    [
      '12',
      'Aspirmatic 2L',
      'realdent',
      [
        'PROTECTIE SI DEZINFECTIE',
      ],
      'RON194.99',
      'https://realdent.ro/index.php/protectie-si-dezinfectie/aspirmatic-2l.html',
      3,
    ],
    [
      '13',
      'Terralin Protect 2l Schulke',
      'realdent',
      [
        'PROTECTIE SI DEZINFECTIE',
      ],
      'RON123.99',
      'https://realdent.ro/index.php/protectie-si-dezinfectie/terralin-protect-2l-schulke.html',
      3,
    ],
    [
      '14',
      'Pulverizator pentru flacon 500 ml & 1000 ml Schulke',
      'realdent',
      [
        'PROTECTIE SI DEZINFECTIE',
      ],
      'RON11.99',
      'https://realdent.ro/index.php/protectie-si-dezinfectie/pulverizator-pentru-flacon-500-ml-1000-ml-schulke.html',
      2,
    ],
    [
      '15',
      'Desderman Pure 1l Schulke',
      'realdent',
      [
        'PROTECTIE SI DEZINFECTIE',
      ],
      'RON42.99',
      'https://realdent.ro/index.php/protectie-si-dezinfectie/desderman-pure-1l-schulke.html',
      4,
    ],
    [
      '16',
      'Terralin Protect 5l Schulke',
      'realdent',
      [
        'PROTECTIE SI DEZINFECTIE',
      ],
      'RON260.99',
      'https://realdent.ro/index.php/protectie-si-dezinfectie/terralin-protect-5l-schulke.html',
      3,
    ],
    [
      '17',
      'Lame bisturiu Prima, otel-carbon, sterile (100 buc)',
      'realdent',
      [
        'CHIRURGIE',
      ],
      '2014',
      'https://realdent.ro/index.php/chirurgie/lame-bisturiu-hotel-carbon-100-buc.html',
      2,
    ],
    [
      '18',
      'Nylon D-TEK Circle CC',
      'realdent',
      [
        'CHIRURGIE',
      ],
      '2019',
      'https://realdent.ro/index.php/chirurgie/nylon-d-tek-circle-cc.html',
      0,
    ],
    [
      '19',
      'Silk D-TEK Circle CC',
      'realdent',
      [
        'CHIRURGIE',
      ],
      '2018',
      'https://realdent.ro/index.php/chirurgie/polypropylene-d-tek-circle-cc.html',
      3,
    ],
    [
      '20',
      'Polypropylene D-TEK Circle CC',
      'realdent',
      [
        'CHIRURGIE',
      ],
      '2017',
      'https://realdent.ro/index.php/chirurgie/polypropylene-d-tek-circle-cc.html',
      1,
    ],
  ];

  public getHeaders() {
    return [
      '#',
      'Book title',
      'Author',
      'First published',
      'Status',
    ];
  }

  public getSimpleTable() {
    return [
      [
        '1',
        'To Kill a Mockingbird',
        'Harper Lee',
        '1960',
        'read',
      ],
      [
        '2',
        'Pride and Prejudice',
        'Jane Austen',
        '1813',
        'unread',
      ],
      [
        '3',
        'The Great Gatsby',
        'F. Scott Fitzgerald',
        '1925',
        'read',
      ],
      [
        '4',
        'Crime and Punishment',
        'Fyodor Dostoyevsky',
        '1866',
        'read',
      ],
    ];
  }

  public getBorderedTable() {
    return [
      [
        '23',
        'Gone with the Wind',
        'Margaret Mitchell',
        '1936',
        'unread',
      ],
      [
        '24',
        'The Count of Monte Cristo',
        'Alexandre Dumas',
        '1845',
        'unread',
      ],
      [
        '25',
        'Lolita',
        'Vladimir Nabokov',
        '1955',
        'read',
      ],
      [
        '26',
        'The Hobbit',
        'J.R.R. Tolkien',
        '1937',
        'unread',
      ],
    ];
  }

  public getStrippedTable() {
    return [
      [
        '54',
        'The Hitchhiker\'s Guide to th...',
        'Douglas Adams',
        '1979',
        'unavailable',
      ],
      [
        '55',
        'Madame Bovary',
        'Gustave Flaubert',
        '1856',
        'available',
      ],
      [
        '56',
        'David Copperfield',
        'Charles Dickens',
        '1850',
        'available',
      ],
      [
        '57',
        'War and Peace',
        'Leo Tolstoy',
        '1869',
        'available',
      ],
    ];
  }

  public getBorderlessTable() {
    return [
      [
        '31',
        'The Little Prince',
        'Antoine de Saint-Exupery',
        '1943',
        'available',
      ],
      [
        '32',
        'Les Miserables',
        'Victor Hugo',
        '1862',
        'unavailable',
      ],
      [
        '33',
        'Alice in Wonderland',
        'Lewis Carroll',
        '1865',
        'available',
      ],
      [
        '34',
        'Anne of Green Gables',
        'L.M. Montgomery',
        '1908',
        'available',
      ],
    ];
  }

  public getAdvancedHeaders() {
    return [
      {
        name: '#',
        sort: null,
      },
      {
        name: 'Produs',
        sort: 0,
      },
      {
        name: 'Furnizor',
        sort: 0,
      },
      {
        name: 'Categorie',
        sort: null,
      },
      {
        name: 'Pret',
        sort: null,
      },
      // {
      //   name: 'Url',
      //   sort: null,
      // },
      {
        name: 'Disponibilitate',
        sort: 0,
      },

    ];
  }

  public getAdvancedTableNumOfPage(countPerPage) {
    return Math.ceil(this.advanceTableData.length / countPerPage);
  }

  public getAdvancedTablePage(page, countPerPage) {
    return this.advanceTableData.slice((page - 1) * countPerPage, page * countPerPage);
  }

  public changeAdvanceSorting(order, index) {
    this.advanceTableData = this.sorting(this.advanceTableData, order, index);
  }

  private sorting(array, order, value) {
    const compareFunction = (a, b) => {
      if (a[value] > b[value]) {
        return 1 * order;
      }
      if (a[value] < b[value]) {
        return -1 * order;
      }
      return 0;
    };
    return array.sort(compareFunction);
  }
}
