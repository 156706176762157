import { Component, Input, OnDestroy } from '@angular/core';

import { SidebarComponent as BaseSidebarComponent } from 'theme/components/sidebar';
import { Router, NavigationEnd } from '@angular/router';
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { DatabaseService } from 'app/connect';
import { GlobalService } from 'app/connect/auth/global.service';
import { fromEvent } from 'rxjs';
// import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-sidebar',
  styleUrls: ['../../../theme/components/sidebar/sidebar.component.scss', './sidebar.component.scss'],
  templateUrl: '../../../theme/components/sidebar/sidebar.component.html',
})
export class SidebarComponent extends BaseSidebarComponent implements OnDestroy {
  public title = 'smart-dent';
  public role;
  public menu: any;

  constructor(private globalSrv: GlobalService) {

    super();

    globalSrv.userRoleValue.subscribe((nextValue) => {
      // alert(nextValue);  // this will happen on every change
      this.role = nextValue;

      //this.role = localStorage.getItem('userRole');
      console.log('this.role', this.role);

      if (this.role == 'developer') {
        this.menu = [
          { name: 'Home', link: '/app/home', icon: 'home' },
          { name: 'Dashboard', link: '/app/dashboard', icon: 'dashboard' },
          { name: 'Custom Dashboard', link: '/app/dashboard-custom', icon: 'view_quilt' },
          {
            name: 'UI',
            children: [
              ...[
                'buttons',
                'cards',
                'colors',
                'forms',
                'icons',
                'typography',
                'tables',
              ].map(ui => ({
                name: ui[0].toUpperCase() + ui.slice(1),
                link: `/ui/${ui}`,
              })),
              {
                name: 'Right sidebar',
                link: '/ui/right-sidebar',
              },
            ],
            icon: 'view_comfy',
          },
          { name: 'Components', link: '/app/components', icon: 'developer_board' },
          // { name: 'Account', link: '/app/forms', icon: 'person' },
          {
            name: 'Maps', icon: 'map', children: [
              { name: 'Simple map', link: '/maps/simple' },
              { name: 'Advanced map', link: '/maps/advanced' },
            ],
          },
          { name: 'Charts', link: '/app/charts', icon: 'multiline_chart' },
          {
            name: 'Pages', children: [
              { name: 'Sign in', link: '/pages/login' },
              { name: 'Sign up', link: '/pages/sign-up' },
              { name: 'Forgot password', link: '/pages/forgot-password' },
              { name: '404', link: '/pages/error' },
            ],
            icon: 'pages',
          },
          { name: 'Places', icon: 'map', link: '/app/places' },
          {
            name: 'Setari', icon: 'settings', children: [
              { name: 'Profile', link: '/app/profile' },
              { name: 'Account', link: '/settings/account' },
              // { name: 'SignOut', link: '/pages/forgot-password' },
            ]
          },
          { name: 'Stock', icon: 'map', link: '/app/stock' },
          { name: 'Echipamente', icon: 'map', link: '/app/echipamente' },
          { name: 'Users', icon: 'map', link: '/app/users' },
        ]
      }

      if (this.role == 'admin') {
        this.menu = [
          // { name: 'Acasa', link: '/app/home', icon: 'home' },
          // { name: 'Acasa', link: 'https://smart-dent.ro/', icon: 'home' },

          // { name: 'Custom Dashboard', link: '/app/dashboard-custom', icon: 'view_quilt' },
          // {
          //   name: 'UI',
          //   children: [
          //     ...[
          //       'buttons',
          //       'cards',
          //       'colors',
          //       'forms',
          //       'icons',
          //       'typography',
          //       'tables',
          //     ].map(ui => ({
          //       name: ui[0].toUpperCase() + ui.slice(1),
          //       link: `/ui/${ui}`,
          //     })),
          //     {
          //       name: 'Right sidebar',
          //       link: '/ui/right-sidebar',
          //     },
          //   ],
          //   icon: 'view_comfy',
          // },
          { name: 'Doctori', icon: 'supervised_user_circle', link: '/app/doctors' },
          // { name: 'Components', link: '/app/components', icon: 'developer_board' },
          // { name: 'Contul meu', link: '/app/forms', icon: 'person' },
          {
            name: 'Administrare', icon: 'font_download', children: [
              { name: 'Stocuri', icon: 'storage', link: '/app/stock' },
              { name: 'Utilizatori', icon: 'map', link: '/app/users' },
              { name: 'Programari', icon: 'map', link: '/app/schedules' },
              { name: 'Pacienti', icon: 'map', link: '/app/patients' },
              // { name: 'Dashboard', link: '/app/dashboard', icon: 'dashboard' },
              // { name: 'Charts', link: '/app/charts', icon: 'multiline_chart' },
              { name: 'Statistici', link: '/app/statistics', icon: 'multiline_chart' },
            ],
          },
          // {
          //   name: 'Servicii', icon: 'device_hub', children: [
          //     ...[
          //       'urgente',
          //       'profilaxie',
          //       'protetica',
          //       'endodontie',
          //       'odontoterapie',
          //       'ortodontie',
          //       'pedodontie',
          //       'chirurgie',
          //     ].map(ui => ({
          //       name: ui[0].toUpperCase() + ui.slice(1),
          //       link: `/app/home/${ui}`,
          //     })),
          //     {
          //       name: 'Right sidebar',
          //       link: '/ui/right-sidebar',
          //     },
          //   ],
          // },
          { name: 'Echipamente', icon: 'map', link: '/app/echipamente' },
          // { name: 'Preturi', link: '/app/home/prices', icon: 'credit_card' },
          // {
          //   name: 'Pages', children: [
          //     { name: 'Sign in', link: '/pages/login' },
          //     { name: 'Sign up', link: '/pages/sign-up' },
          //     { name: 'Forgot password', link: '/pages/forgot-password' },
          //     { name: '404', link: '/pages/error' },
          //   ],
          //   icon: 'pages',
          // },
          {
            name: 'Setari', icon: 'settings', children: [
              { name: 'Profile', link: '/settings/profile' },
              // { name: 'Account', link: '/settings/account' },
              // { name: 'SignOut', link: '/pages/forgot-password' },
            ]
          },

        ]
      }

      if (this.role == 'doctor') {
        this.menu = [
          { name: 'Acasa', link: '/app/home', icon: 'home' },

          { name: 'Doctori', icon: 'supervised_user_circle', link: '/app/doctors' },
          // { name: 'Account', link: '/app/forms', icon: 'person' },
          // {
          //   name: 'Servicii', icon: 'device_hub', children: [
          //     ...[
          //       'urgente',
          //       'profilaxie',
          //       'protetica',
          //       'endodontie',
          //       'odontoterapie',
          //       'ortodontie',
          //       'pedodontie',
          //       'chirurgie',
          //     ].map(ui => ({
          //       name: ui[0].toUpperCase() + ui.slice(1),
          //       link: `/app/home/${ui}`,
          //     })),
          //     {
          //       name: 'Right sidebar',
          //       link: '/ui/right-sidebar',
          //     },
          //   ],
          // },
          { name: 'Programari', icon: 'map', link: '/app/schedules' },
          { name: 'Pacienti', icon: 'map', link: '/app/patients' },
          { name: 'Echipamente', icon: 'map', link: '/app/echipamente' },
          // { name: 'Preturi', link: '/app/home/prices', icon: 'credit_card' },
          // {
          //   name: 'Pages', children: [
          //     { name: 'Sign in', link: '/pages/login' },
          //     { name: 'Sign up', link: '/pages/sign-up' },
          //     { name: 'Forgot password', link: '/pages/forgot-password' },
          //     { name: '404', link: '/pages/error' },
          //   ],
          //   icon: 'pages',
          // },
          {
            name: 'Setari', icon: 'settings', children: [
              { name: 'Profile', link: '/settings/profile' },
              // { name: 'Account', link: '/settings/account' },
              // { name: 'SignOut', link: '/pages/forgot-password' },
            ]
          },

        ]
      }

      if (this.role == 'user' || this.role == undefined) {
        this.menu = [
          { name: 'Acasa', link: '/app/home', icon: 'home' },

          { name: 'Doctori', icon: 'supervised_user_circle', link: '/app/doctors' },
          // { name: 'Account', link: '/app/forms', icon: 'person' },
          // {
          //   name: 'Servicii', icon: 'device_hub', children: [
          //     ...[
          //       'urgente',
          //       'profilaxie',
          //       'protetica',
          //       'endodontie',
          //       'odontoterapie',
          //       'ortodontie',
          //       'pedodontie',
          //       'chirurgie',
          //     ].map(ui => ({
          //       name: ui[0].toUpperCase() + ui.slice(1),
          //       link: `/app/home/${ui}`,
          //     })),
          //     {
          //       name: 'Right sidebar',
          //       link: '/ui/right-sidebar',
          //     },
          //   ],
          // },
          { name: 'Echipamente', icon: 'map', link: '/app/echipamente' },
          // { name: 'Preturi', link: '/app/home/prices', icon: 'credit_card' },
          // {
          //   name: 'Pages', children: [
          //     { name: 'Sign in', link: '/pages/login' },
          //     { name: 'Sign up', link: '/pages/sign-up' },
          //     { name: 'Forgot password', link: '/pages/forgot-password' },
          //     { name: '404', link: '/pages/error' },
          //   ],
          //   icon: 'pages',
          // },
          {
            name: 'Setari', icon: 'settings', children: [
              { name: 'Profile', link: '/settings/profile' },
              // { name: 'Account', link: '/settings/account' },
              // { name: 'SignOut', link: '/pages/forgot-password' },
            ]
          },

        ]
      }

      this.menu.push(
        { name: 'Termeni GDPR', icon: 'map', link: '/app/gdpr' },
      )

    });


    // fromEvent(window, 'storage').subscribe((storageEvent) => {
    //   alert(storageEvent);
    // })


  }

  ngOnDestroy() {

  }

}
