import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../connect/auth/auth-guard.service';
import { UpgradableComponent } from 'theme/components/upgradable';

@Component({
  selector: 'wm-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends UpgradableComponent {

  //constructor(readonly auth: AuthGuard) { }

}