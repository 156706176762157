import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profilaxie',
  templateUrl: './profilaxie.component.html',
  styleUrls: ['./profilaxie.component.scss']
})
export class ProfilaxieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
