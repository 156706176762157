import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { DatabaseDocument, DatabaseService, AuthService, UploadTask, StorageService } from 'app/connect';
import { ActivatedRoute, } from '@angular/router';
import { Location } from '@angular/common';
import { dbProduct } from '../echipament/echipament.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-edit-echipament',
  templateUrl: './edit-echipament.component.html',
  styleUrls: ['./edit-echipament.component.scss']
})
export class EditEchipamentComponent extends DatabaseDocument<dbProduct> implements OnInit, OnDestroy {
  @HostBinding('class.employer-form') private readonly productForm = true;
  public categories = ['ANESTEZIE', 'AMPRENTARE', 'PROTECTIE SI DEZINFECTIE', 'CHIRURGIE', 'ECHIPAMENT'];

  product$: dbProduct;
  readonly photo$: Observable<string>;

  constructor(
    db: DatabaseService,
    private storage: StorageService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private location: Location
  ) {

    super(db, db.doc(`stock/${localStorage.getItem('editingProductId')}`));

    this.stream().pipe().subscribe(response => {
      console.log('product', response);
      this.product$ = response;
    });

    this.photo$ = this.stream().pipe(map(product => !!product ? product.photo : ''));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    localStorage.removeItem("editingProductId");
  }

  categorySelected(category) {
    this.product$.category = category.value != undefined ? category.value : category;
  }

  saveProduct(product) {
    this.update(product);
    this.location.back();
  }

  public uploadTask: UploadTask;

  public uploadPhoto(file: File): Promise<void> {

    if (!file) { return Promise.resolve(null); }

    // Creates the uploading task, this will display the progress bar in the view
    return (this.uploadTask = this.storage.upload(`${file.name}`, file))
      // Returns the url
      .then(snap => snap.ref.getDownloadURL())
      // Updates the profile with the new url
      .then(photo => {
        this.product$.photo = photo;
        this.update(this.product$);
      })
      // Deletes the task object removing the progress bar from the view
      .then(() => (delete this.uploadTask, null));
  }

  public deletePhoto(): Promise<void> {

    // Deletes the file in the storage first
    return this.deleteFile()
      // Resets the photo url into the profile
      .then(() => {
        this.product$.photo = null;
        this.update(this.product$)
      });
  }

  private deleteFile(): Promise<void> {
    // Reads the profile to get the photo url
    const ref = this.storage.refFromURL(this.product$.photo);
    //... and deletes the file
    return ref.delete();
  }

  back() {
    this.location.back();
  }

}
