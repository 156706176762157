import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { CalendarSchedulerEvent, CalendarSchedulerEventAction } from 'angular-calendar-scheduler';
import { dbEvent } from '../schedule.model';
import { DatabaseService, } from 'app/connect';
import { dbUser } from 'app/connect/auth/user-profile.service';
import { ActivatedRoute, Params, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Delete\' on event ' + event.id);
      }
    },
    {
      when: 'disabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
      title: 'Restore',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Restore\' on event ' + event.id);
      }
    }
  ];
  events$: CalendarSchedulerEvent[];

  columns: any[];// = [];
  // rows: CalendarSchedulerEvent[] = [];
  rowsCache: CalendarSchedulerEvent[] = [];
  selected: any[] = [];
  selectedCache: any[] = [];
  loadingIndicator: boolean;
  userId;
  private role;
  users$: dbUser[];

  @ViewChild('scheduleTable') table: any;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('textTemplate')
  textTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  constructor(
    private appService: ScheduleService,
    private db: DatabaseService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.role = localStorage.getItem('userRole');
    this.userId = localStorage.getItem('uid');

    this.db.collection(`users`).stream().subscribe((res: dbUser[]) => {
      this.users$ = res;
      // console.log('pacient', e);
    })
  }

  ngOnInit() {
    // this.route.params.subscribe((params: Params) => {
    //   this.userId = params['id'];
    //   console.log('targetUserId', params['id']);


    if (this.role == 'doctor') {
      this.appService.getDbEventsForTarget(this.actions, this.userId).pipe().subscribe((events: dbEvent[]) => {
        this.events$ = events;
        events.forEach((e, index) => {
          if (e.targetUserId) {
            (<any>e).targetName = this.users$.find(u => u.id == e.targetUserId).name;
          }
          if (e.requestUserId) {
            (<any>e).requestName = this.users$.find(u => u.id == e.requestUserId).name;
          }
        });
        this.loadingIndicator = false;
        console.log('events', events);
      })
    }
    else if (this.role == 'user') {
      this.appService.getDbEventsForRequestor(this.actions, this.userId).pipe().subscribe((events: dbEvent[]) => {
        this.events$ = events;
        events.forEach((e, index) => {
          if (e.targetUserId) {
            (<any>e).targetName = this.users$.find(u => u.id == e.targetUserId).name;
          }
          if (e.requestUserId) {
            (<any>e).requestName = this.users$.find(u => u.id == e.requestUserId).name;
          }
        });
        this.loadingIndicator = false;
        console.log('events', events);
      })
    }
    else if (this.role == 'admin') {
      this.appService.getDbEvents(this.actions).pipe().subscribe((events: dbEvent[]) => {
        this.events$ = events;
        events.forEach((e, index) => {
          if (e.targetUserId) {
            // const target = this.db.document(`users/${e.targetUserId}`).stream().subscribe((res: dbUser) => {
            //   (<any>e).targetName = res.name;
            //   // console.log('doctor', e);
            // })
            (<any>e).targetName = this.users$.find(u => u.id == e.targetUserId).name;
          }
          if (e.requestUserId) {
            // const requestor = this.db.document(`users/${e.requestUserId}`).stream().subscribe((res: dbUser) => {
            //   (<any>e).requestName = res.name;
            //   // console.log('pacient', e);
            // })
            (<any>e).requestName = this.users$.find(u => u.id == e.requestUserId).name;
          }
        });
        this.loadingIndicator = false;
        console.log('events', events);
      })
    }
    // });


  }

  formatDate(date) {
    const formattedDate = date.toLocaleDateString('ro', {
      day: 'numeric', month: 'short', year: 'numeric'
    });
    // }).replace(/ /g, '-');
    //console.log(formattedDate);
    return formattedDate;
  }

  startEdit(row: dbEvent) {
    console.log('row', row);
    localStorage.setItem('editingUserId', row.requestUserId);
    // this.router.navigate(['app/user/', user.id]);
    this.router.navigate(['app/records']);
  }
}
