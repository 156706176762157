import { Component, OnInit, Input } from '@angular/core';
import { CalendarSchedulerEvent, CalendarSchedulerEventAction } from 'angular-calendar-scheduler';
import { ScheduleService } from 'app/schedule/schedule.service';
import { DatabaseService } from 'app/connect';
import { ActivatedRoute } from '@angular/router';
import { MatCard, MatCardHeader, MatCardContent, MatDialog } from '@angular/material';
import { dbEvent } from 'app/schedule/schedule.model';
import { dbUser } from 'app/connect/auth/user-profile.service';
import { loremIpsum } from "lorem-ipsum";



@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {

  @Input('userId') userId: string;
  isEditing: boolean;

  // lorem = loremIpsum({
  //   count: 1,                // Number of "words", "sentences", or "paragraphs"
  //   format: "plain",         // "plain" or "html"
  //   paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
  //   paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
  //   random: Math.random,     // A PRNG function
  //   sentenceLowerBound: 5,   // Min. number of words per sentence.
  //   sentenceUpperBound: 15,  // Max. number of words per sentence.
  //   suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
  //   units: "sentences",      // paragraph(s), "sentence(s)", or "word(s)"
  //   // words: ["ad",]       // Array of words to draw from
  // })

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Delete\' on event ' + event.id);
      }
    },
    {
      when: 'disabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
      title: 'Restore',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Restore\' on event ' + event.id);
      }
    }
  ];
  events$: CalendarSchedulerEvent[];
  loadingIndicator: boolean;

  constructor(
    private appService: ScheduleService,
    private db: DatabaseService,
    private route: ActivatedRoute,
  ) {
    if (!this.userId) {
      this.userId = localStorage.getItem('editingUserId');
    }
  }

  ngOnInit() {
    this.appService.getDbEventsForRequestor(this.actions, this.userId).pipe().subscribe((events: dbEvent[]) => {
      this.events$ = events;
      events.forEach((e, index) => {
        if (e.targetUserId) {
          const target = this.db.document(`users/${e.targetUserId}`).stream().subscribe((res: dbUser) => {
            (<any>e).targetName = res.name;
            // console.log('doctor', e);
          })
        }
        if (e.requestUserId) {
          const requestor = this.db.document(`users/${e.requestUserId}`).stream().subscribe((res: dbUser) => {
            (<any>e).requestName = res.name;
            // console.log('pacient', e);
          })
        }
        //treatment

        if (e.content == "EARLY EVENT")
          e.content = loremIpsum({
            count: 3,                // Number of "words", "sentences", or "paragraphs"
            format: "plain",         // "plain" or "html"
            paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
            paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
            random: Math.random,     // A PRNG function
            sentenceLowerBound: 5,   // Min. number of words per sentence.
            sentenceUpperBound: 15,  // Max. number of words per sentence.
            suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
            units: "paragraphs",      // paragraph(s), "sentence(s)", or "word(s)"
            // words: ["ad",]       // Array of words to draw from
          });

        if (!e.cost) e.cost = Math.round(Math.random() * 100) * 5;

        // (<any>e).toAdd = loremIpsum({
        //   count: 3,                // Number of "words", "sentences", or "paragraphs"
        //   format: "plain",         // "plain" or "html"
        //   paragraphLowerBound: 3,  // Min. number of sentences per paragraph.
        //   paragraphUpperBound: 7,  // Max. number of sentences per paragarph.
        //   random: Math.random,     // A PRNG function
        //   sentenceLowerBound: 5,   // Min. number of words per sentence.
        //   sentenceUpperBound: 15,  // Max. number of words per sentence.
        //   suffix: "\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
        //   units: "sentences",      // paragraph(s), "sentence(s)", or "word(s)"
        //   // words: ["ad",]       // Array of words to draw from
        // })

      });



      this.loadingIndicator = false;
      console.log('events', events);
    })
    // console.log('lorem', this.lorem);
  }

  editEvent(event: dbEvent) {
    (<any>event).isEditing = true;
    // alert(event.content);
  }

  saveEvent(event: dbEvent) {
    (<any>event).isEditing = null;
    this.appService.updateEvent(event);
  }

  cancelEditEvent(event: dbEvent) {
    (<any>event).isEditing = null;
  }

}
