import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsComponent } from './settings.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from '../connect/auth/auth-guard.service';
import { LayoutsModule } from 'app/layouts';
// import { CommonLayoutComponent } from 'app/layouts/common-layout';
import { ThemeModule } from 'theme';
// import { BlankLayoutComponent } from 'app/layouts/blank-layout';
import { CommonLayoutComponent } from 'app/layouts/common-layout';
// import { BlankLayoutComponent } from 'app/layouts/blank-layout';

const routes: Routes = [

  // { path: 'profile', redirectTo: 'app/settings/profile', pathMatch: 'full' },
  // { path: 'account', redirectTo: 'app/settings/account', pathMatch: 'full' },

  {
    path: '', component: CommonLayoutComponent,

    children: [
      // { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent, pathMatch: 'full' },
      { path: 'account', component: AccountComponent, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    LayoutsModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    SettingsComponent,
    ProfileComponent,
    AccountComponent
  ],
  exports: [RouterModule]
})
export class SettingsModule { }