import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-odontoterapie',
  templateUrl: './odontoterapie.component.html',
  styleUrls: ['./odontoterapie.component.scss']
})
export class OdontoterapieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
