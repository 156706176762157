import { Injectable } from '@angular/core';
import { DatabaseService, DatabaseCollection } from 'app/connect';
import { Observable } from 'rxjs';
import { dbEvent } from 'app/schedule/schedule.model';
import { map } from 'rxjs/operators';

export interface Doctor {
  key: string,
  value: number;
  y: number;
}

@Injectable()
export class DoctorStatisticsService extends DatabaseCollection<dbEvent> {

  // total: number;
  // data: Doctor[] = [];

  constructor(db: DatabaseService) {
    super(db, db.col('schedules'));

    // this.stream().pipe().subscribe((res: dbEvent[]) => {
    //   this.total = 0;
    //   this.data = [];
    //   res.forEach(e => {
    //     if (e.cost) {
    //       this.total += e.cost;
    //       let name = (<any>e).targetName;
    //       let d = this.data.find(x => x.key == name);
    //       if (d) { d.value += e.cost } else {
    //         this.data.push({ key: name, value: e.cost, y: e.cost * 100 / this.total });
    //       }
    //     }
    //   })

    //   console.log('doctors', this.data);
    //   console.log('total', this.total);
    // });

  }

  public getDoctorStatistics() {
    return [
      {
        key: 'Dr. A',
        y: 87,
      },
      {
        key: 'Dr. B',
        y: 13,
      },
    ];
  }

  public getDbDoctorStatistics(): Observable<Doctor[]> {

    // return this.data.map((d) => {
    //   // console.log('elem', { key: d.name, y: d.value + 100 / this.total })
    //   return { key: d.key, y: d.value + 100 / this.total }
    //   // return d;
    // });

    return this.stream(ref => ref.orderBy('start', 'desc')).pipe(map((res: dbEvent[]) => {
      let total = 0;
      const data = [];
      res.forEach(e => {
        if (e.cost) {
          total += e.cost;
          let name = (<any>e).targetName;
          let d = data.find(x => x.key == name);
          if (d) { d.value += e.cost } else { data.push({ key: name, value: e.cost, }); }
        }
      });

      data.map(d => {
        d.y = d.value * 100 / total;
      })

      console.log('doctors', data);
      console.log('total', total);
      return data;
    }));
  }
}
