import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UserProfile } from 'app/connect/auth/user-profile.service';

export class Option {
  type: string;
  date: Date;
  isApproved?: boolean;
  minutes?: number;
}

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.css']
})
export class SelectDateComponent implements OnInit {

  options: string[] = ['Consultatie', 'Urgenta', 'Altele'];
  submitText: string = 'Salveaza';

  dateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(
    public dialogRef: MatDialogRef<string>,
    public profile: UserProfile,
    @Inject(MAT_DIALOG_DATA) public data: Option = new Option(),
  ) { }

  ngOnInit() {
  }

  submit() {
    // let date = this.data;
    console.log('data', this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Option>) {
    // this.events.push(`${type}: ${event.value}`);
  }

  get canApprove() {
    return this.profile.data.role.value == 'doctor' || this.profile.data.role.value == 'admin';
  }

}