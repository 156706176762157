import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from 'theme';
import { ScheduleComponent } from './schedule.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerModule, } from 'angular-calendar-scheduler';
import { ScheduleService } from './schedule.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatNativeDateModule,
    MatCheckboxModule
} from '@angular/material';


import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SelectDateComponent } from './select-date/select-date.component';
import { FormsModule } from '@angular/forms';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { NotificationMenuService } from 'app/components/notification-menu';

registerLocaleData(localeIt);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ThemeModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        SchedulerModule.forRoot({ locale: 'ro', headerDateFormat: 'daysRange' }),
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,
        MatRadioModule,
        MatCheckboxModule,
        MatNativeDateModule,
        NgxDatatableModule,
    ],
    declarations: [
        ScheduleComponent,
        SelectDateComponent,
        ScheduleListComponent
    ],
    exports: [
        ScheduleComponent
    ],
    entryComponents: [
        SelectDateComponent
    ],
    providers: [
        ScheduleService,
        NotificationMenuService,
        { provide: LOCALE_ID, useValue: 'en-US' }
    ],
    // providers: [MapsService],
})
export class ScheduleModule { }
