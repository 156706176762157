import { Component, OnInit } from '@angular/core';
import { dbCommon, DatabaseService, DatabaseCollection } from 'app/connect';
import { Observable } from 'rxjs';
import { dbProduct } from './echipament/echipament.component';


// interface dbProduct extends dbCommon {
//   // id: number;
//   name: string;
//   supplier: string;
//   category: string;// string[];
//   price: string;
//   url: string;
//   availability: string;
//   equipment: boolean;
// }

@Component({
  selector: 'app-echipamente',
  templateUrl: './echipamente.component.html',
  styleUrls: ['./echipamente.component.scss']
})
export class EchipamenteComponent extends DatabaseCollection<dbProduct> implements OnInit {

  readonly products$: Observable<dbProduct[]>;

  constructor(db: DatabaseService) {
    // Constructs the DatabseColleciton referring to 'places'
    super(db, db.col('stock'));
    // Streams the array of places ordering them by ascending names 
    this.products$ = this.stream(ref => ref.where('category', '==', 'ECHIPAMENT').orderBy('name', 'asc'));
    // this.products$ = this.stream(ref => ref.orderBy('name', 'asc'));
  }
  ngOnInit() {
  }

}
