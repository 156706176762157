import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { AuthInterceptor, AuthService, FakeBackendInterceptor } from '@services/*';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from './pages/charts';
import { ComponentsModule } from './pages/components';
import { DashboardModule } from './pages/dashboard';
import { Dashboard2Module } from './pages/dashboard2';
import { FormsModule } from './pages/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatDialogModule } from '@angular/material/dialog';

import { ConnectModule, ConnectConfig, AuthModule, DatabaseModule, StorageModule } from './connect';
import { LoginModule } from './login/login.module';
import { PlacesModule } from './pages/places/places.module';
import { SettingsModule } from './settings/settings.module';
import { StockModule } from './stock';
import { ManageUsersModule } from './manage-users';
import { HomeModule } from './home/home.module';
import { DoctorsModule } from './doctors';
import { ScheduleModule } from './schedule';
import { EchipamenteModule } from './echipamente';
import { PatientsModule } from './patients';
import { GlobalService } from './connect/auth/global.service';
import { GdprModule } from './gdpr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationDialog } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBarModule } from '@angular/material';

// import { ModalModule } from 'ngx-bootstrap';

// import { AngularFireModule } from '@angular/fire';
//import { AngularFirestoreModule } from '@angular/fire/firestore';





// Firebase configuration for orto-dent experiments
const config: ConnectConfig = {
  appname: 'orto-dent',
  firebase: {
    apiKey: "AIzaSyDsIC2QyTVV37kMuR1VThsrYUI8l6O40ts",
    authDomain: "orto-dent.firebaseapp.com",
    databaseURL: "https://orto-dent.firebaseio.com",
    projectId: "orto-dent",
    storageBucket: "orto-dent.appspot.com",
    messagingSenderId: "990683965755",
    appId: "1:990683965755:web:9c27037dc7468a991daf1e"
  }
};

// var firebaseConfig = {
//   apiKey: "AIzaSyDsIC2QyTVV37kMuR1VThsrYUI8l6O40ts",
//   authDomain: "orto-dent.firebaseapp.com",
//   databaseURL: "https://orto-dent.firebaseio.com",
//   projectId: "orto-dent",
//   storageBucket: "orto-dent.appspot.com",
//   messagingSenderId: "990683965755",
//   appId: "1:990683965755:web:9c27037dc7468a991daf1e"
// };

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialog,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    MatSnackBarModule,
    AppRoutingModule,
    ComponentsModule,
    DashboardModule,
    Dashboard2Module,
    FormsModule,
    ChartsModule,
    StockModule,
    HttpClientModule,
    GdprModule,
    // ModalModule.forRoot(),

    // AngularFireModule.initializeApp(firebaseConfig, 'orto-dent'),
    //AngularFirestoreModule,
    // Initialize the connect module
    ConnectModule.init(config),

    AuthModule,
    DatabaseModule,
    StorageModule,
    LoginModule,
    PlacesModule,
    SettingsModule,
    ManageUsersModule,
    HomeModule,
    DoctorsModule,
    EchipamenteModule,
    ScheduleModule,
    PatientsModule,
    ModalModule.forRoot(),
  ],
  providers: [
    //AuthService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: FakeBackendInterceptor,
    //   multi: true,
    // },
    GlobalService
  ],
  entryComponents: [ConfirmationDialog],
  bootstrap: [AppComponent],

})
export class AppModule { }
