import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'base-sidebar',
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  @Input() public menu;
  @Input() public title = 'darkboard';
  role: string;

  constructor() {
    this.role = localStorage.getItem('userRole');
  }

  goHome() {
    // this.router.navigate(['/']);
  }
}
