import { Component, ElementRef, OnInit } from '@angular/core';

import { LineChartComponent } from 'theme/components/line-chart';

import { LineChartRevenueService } from './line-chart-revenue.service';

@Component({
  selector: 'app-line-chart-revenue',
  styleUrls: ['../../../../theme/components/line-chart/line-chart.component.scss'],
  template: ``,
  providers: [LineChartRevenueService],
})
export class LineChartRevenueComponent extends LineChartComponent {
  constructor(
    el: ElementRef,
    private lineChartRevenueService: LineChartRevenueService,
  ) {
    super(el);

    this.xAxis = 'X';
    this.yAxis = 'Y';
    this.maxX = 14;

    this.afterConfigure();

    this.animatedData = [
      {
        values: [],
        key: 'Incasari',
        color: '#00bcd4',
        fillOpacity: 0.00001,
        area: true,
      },
      // {
      //   values: [],
      //   key: 'Orange graph',
      //   color: '#ffc107',
      //   fillOpacity: 0.00001,
      //   area: true,
      // },
      {
        values: [],
        key: 'Cheltuieli',
        color: '#00d45a',
        fillOpacity: 0.00001,
      },
    ];
    this.rawData = [
      lineChartRevenueService.getTealGraph,
      // lineChartRevenueService.getOrangeGraph,
      lineChartRevenueService.getGreenGraph,
    ]
      .map(f => f.bind(lineChartRevenueService))
      .map(f => [...f(0, this.maxX + 1, this.xStep)]);
  }
}
