import { Component, OnInit, LOCALE_ID, Inject, HostListener } from '@angular/core';
import {
  CalendarDateFormatter, CalendarView, DateAdapter, CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent, CalendarDayViewComponent, DAYS_OF_WEEK,
} from 'angular-calendar';
import {
  SchedulerDateFormatter,
  CalendarSchedulerEvent,
  SchedulerViewHourSegment,
  SchedulerViewHour,
  SchedulerViewDay,
  CalendarSchedulerEventAction,
  SchedulerEventTimesChangedEvent,
  subPeriod,
  addPeriod,
  endOfPeriod,
  startOfPeriod,
  CalendarSchedulerEventStatus,
  // DAYS_IN_WEEK,
} from 'angular-calendar-scheduler';

import {
  endOfDay,
  addMonths,
  subHours,
  addDays,
  startOfHour,
  subMinutes,
  addHours,
  setHours,
  addMinutes,
} from 'date-fns';
import { isDate } from 'moment';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { map, take, switchMap, tap } from 'rxjs/operators';

import { dbCommon, DatabaseCollection, DatabaseService, dbTimestamp, DatabaseDocument, AuthService } from 'app/connect';

import { ScheduleService } from './schedule.service';
// import { Timestamp } from '@firebase/firestore-types';
import { firestore, auth } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { SelectDateComponent } from './select-date/select-date.component';
import { MatDialog } from '@angular/material';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { dbEvent } from './schedule.model';
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Notification } from 'app/components/notification-menu/notification.model';
import { NotificationMenuService } from 'app/components/notification-menu/notification-menu.service';
// import { DAYS_IN_WEEK } from 'angular-calendar-scheduler/modules/scheduler/utils/calendar-scheduler-utils';

//import undefined = require('firebase/empty-import');

// export interface dbEvent extends  CalendarSchedulerEvent {
//   name: string;
//   id: string;
//   events: CalendarSchedulerEvent[];
// };

// export interface dbEvent extends CalendarSchedulerEvent {
//   // id?      : string,
//   created?: dbTimestamp,
//   updated?: dbTimestamp,
//   requestUserId?: string,
//   targetUserId?: string,
//   isApproved?: boolean
// };

export class Option {
  type: string;
  date: Date;
  isApproved?: boolean;
  minutes?: number;
}


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: SchedulerDateFormatter
  }]
})
export class ScheduleComponent extends DatabaseCollection<dbEvent> implements OnInit {

  title = 'Angular Calendar Scheduler Demo';

  CalendarView = CalendarView;

  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = 7;// DAYS_IN_WEEK;
  refreshSubject: Subject<any> = new Subject();
  locale: string = 'ro';
  hourSegments: number = 4;
  weekStartsOn: number = 1;
  startsWithToday: boolean = true;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = []; // [0];
  weekendDays: number[] = [0, 6];
  dayStartHour: number = 8;
  dayEndHour: number = 22;

  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  targetUserId: string;
  private _patient$ = new BehaviorSubject<boolean>(false);

  private patients: DatabaseCollection<any>;

  /** Returns the current authenticated userId or 'unknown' */
  get me(): string { return this.guard.userId || 'unknown'; }
  /** Returns true whenever the current user is authenticated */
  get authenticated(): boolean { return this.guard.authenticated; }

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Delete\' on event ' + event.id);
        this.deleteEvent(event.id);
      }
    },
    {
      when: 'disabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
      title: 'Restore',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Restore\' on event ' + event.id);
      }
    }
  ];

  // events: Observable<CalendarSchedulerEvent[]>;
  readonly events$: Observable<dbEvent[]>;
  events: dbEvent[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustViewDays();
  }

  constructor(
    @Inject(LOCALE_ID) locale: string,
    db: DatabaseService,
    private dateAdapter: DateAdapter,
    private appService: ScheduleService,
    private dialog: MatDialog,
    private guard: AuthGuard,
    private auth: AuthService,
    private route: ActivatedRoute,
    private notificationService: NotificationMenuService) {


    super(db, db.col('schedules'));



    this.route.params.subscribe((params: Params) => {
      this.targetUserId = params['id'];
      console.log('targetUserId', this.targetUserId);

      if (this.targetUserId) {
        this.stream(ref => ref.where('targetUserId', '==', this.targetUserId)).pipe(map((res: any) => {
          res.forEach(e => {
            e.start = (<Timestamp>e.start).toDate();
            e.end = (<Timestamp>e.end).toDate();
            e.actions = this.actions;
          })
          return res;
        })).subscribe(e => {
          this.events = e;
          console.log('events', e);
        });
      } else {
        this.stream().pipe(map((res: any) => {
          res.forEach(e => {
            e.start = (<Timestamp>e.start).toDate();
            e.end = (<Timestamp>e.end).toDate();
            e.actions = this.actions;
          })
          return res;
        })).subscribe(e => {
          this.events = e;
          console.log('events', e);
        });
      }


      this.patients = this.db.document(`users/${this.targetUserId}`).collection('patients');
      console.log('patients', this.patients);
    })



    this.locale = locale;

    this.dayModifier = ((day: SchedulerViewDay): void => {
      if (!this.isDateValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    }).bind(this);
    this.hourModifier = ((hour: SchedulerViewHour): void => {
      if (!this.isDateValid(hour.date)) {
        hour.cssClass = 'cal-disabled';
      }
    }).bind(this);
    this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
      if (!this.isDateValid(segment.date)) {
        segment.isDisabled = true;
      }
    }).bind(this);

    this.adjustViewDays();
    this.dateOrViewChanged();
  }

  ngOnInit(): void {
    //https://github.com/mounthorse-slns/angular-calendar-scheduler/blob/master/src/app/services/app.service.ts

  }

  adjustViewDays(): void {
    this.excludeDays = [];
    const n = this.viewDate.getDay();
    // See 'Responsive breakpoints' at https://getbootstrap.com/docs/4.1/layout/overview/
    const currentWidth: number = window.innerWidth;
    if (currentWidth <= 576) {          // Extra small devices (portrait phones, less than 576px)
      this.viewDays = 1;
      this.view = CalendarView.Day;
      for (let index = n + 1; index <= n + 7 - 1; index++) {
        this.excludeDays.push(index % 7);
      }
    } else if (currentWidth <= 768) {   // Small devices (landscape phones, less than 768px)
      this.view = CalendarView.Day;
      this.viewDays = 3;
      for (let index = n + 3; index <= n + 7 - 1; index++) {
        this.excludeDays.push(index % 7);
      }
    } else {
      this.view = CalendarView.Week;
      this.viewDays = 7;// DAYS_IN_WEEK;
    }
    console.log('exclude days', currentWidth, n, this.excludeDays);
  }

  changeDate(date: Date): void {
    console.log('changeDate', date);
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    console.log('changeView', view);
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(subPeriod(this.dateAdapter, this.view, this.viewDate, 1));
      this.nextBtnDisabled = !this.isDateValid(addPeriod(this.dateAdapter, this.view, this.viewDate, 1));
    } else {
      this.prevBtnDisabled = !this.isDateValid(endOfPeriod(this.dateAdapter, this.view, subPeriod(this.dateAdapter, this.view, this.viewDate, 1)));
      this.nextBtnDisabled = !this.isDateValid(startOfPeriod(this.dateAdapter, this.view, addPeriod(this.dateAdapter, this.view, this.viewDate, 1)));
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
    this.adjustViewDays();
  }

  private isDateValid(date: Date): boolean {
    return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
    console.log('dayHeaderClicked Day', day);
  }

  hourClicked(hour: SchedulerViewHour): void {
    console.log('hourClicked Hour', hour);
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
    console.log('segmentClicked Action', action);
    console.log('segmentClicked Segment', segment);
    this.createEvent(segment);
  }

  eventClicked(action: string, event: dbEvent): void {
    console.log('eventClicked Action', action);
    console.log('eventClicked Event', event);

    this.editEvent(event);
  }

  eventTimesChanged({ event, newStart, newEnd }: SchedulerEventTimesChangedEvent): void {
    console.log('eventTimesChanged Event', event);
    console.log('eventTimesChanged New Times', newStart, newEnd);
    // let ev = this.events$.find(e => e.id === event.id);
    this.events$.pipe(map(x => x.find(e => e.id == event.id)))
      .subscribe(ev => {
        ev.start = newStart;
        ev.end = newEnd;
        this.refreshSubject.next();
      });
    // ev.start = newStart;
    // ev.end = newEnd;
    // this.refreshSubject.next();
  }

  createEvent(segment: SchedulerViewHourSegment) {
    this.isAuthenticated();
    const option: Option = { date: segment.date, type: 'Consultatie', isApproved: false, minutes: 60 / this.hourSegments };
    const dialogRef = this.dialog.open(SelectDateComponent, {
      data: option,
    });

    console.log('before dialog close');

    dialogRef.afterClosed().pipe(take(1)).subscribe((result: Option) => {
      if (result) {
        console.log('selectedDate', result);
        status = 'ok';//consultatie
        if (result.type == 'Urgenta') status = 'danger';
        if (result.type == 'Altele') status = 'warning';

        result.minutes = Math.trunc(result.minutes / 15) * 15;
        if (result.minutes < 15) result.minutes = 15;

        const event = <dbEvent>{
          // id: '5',
          start: segment.date,// addDays(startOfHour(setHours(new Date(), 6)), 2),
          end: addMinutes(segment.date, result.minutes),// addHours(addDays(startOfHour(setHours(new Date(), 6)), 2), 1),
          title: result.type,
          content: 'EARLY EVENT',
          color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
          //actions: this.actions,
          status: status as CalendarSchedulerEventStatus,
          isClickable: true,
          isDisabled: false,
          isCancelled: false,
          cost: 0,
          requestUserId: this.auth.user.uid,
          targetUserId: this.targetUserId,
          isApproved: result.isApproved,
          minutes: result.minutes,
        };

        console.log('before save', event);

        // this.add(ev).then(result => {
        //   console.log('save result', result);
        // })
        // this.db.col("schedules").add(JSON.parse(JSON.stringify(ev))).then(result => {
        this.db.col("schedules").add(event).then(result => {


          console.log('saved event', result.id);
          event.id = result.id;
          event.actions = this.actions;

          //create notification
          const notification = <Notification>{
            text: event.title,
            time: 'just now',
            icon: 'plus_one',
            color: 'primary',
            start: event.start,
            end: event.end,
            userId: this.targetUserId,
            isApproved: event.isApproved
          };
          //this.events.push(event);
          this.notificationService.add(notification);
        });
        this.toggleFavorite();
      }
    });

  }

  editEvent(event: dbEvent) {
    this.isAuthenticated();
    const option: Option = { date: event.start, type: 'Consultatie', isApproved: event.isApproved, minutes: event.minutes };
    const dialogRef = this.dialog.open(SelectDateComponent, {
      data: option,
    });

    console.log('before dialog close');

    dialogRef.afterClosed().pipe(take(1)).subscribe((result: Option) => {
      if (result) {
        console.log('selectedDate', result);
        status = 'ok';//consultatie
        if (result.type == 'Urgenta') status = 'danger';
        if (result.type == 'Altele') status = 'warning';

        event.isApproved = result.isApproved;
        event.actions = null;
        event.targetUserId = this.auth.user.uid;
        if (event.isApproved == true) {
          event.color = { primary: '#d1b3ff', secondary: '#809fff' };
        }
        event.minutes = result.minutes;

        console.log('before save', event);

        // this.add(ev).then(result => {
        //   console.log('save result', result);
        // })
        // this.db.col("schedules").add(JSON.parse(JSON.stringify(ev))).then(result => {
        this.db.doc(`schedules/${event.id}`).update(event).then(result => {
          console.log('saved event', result);
          //this.events.push(event);

          //create notification
          const notification = <Notification>{
            text: event.title,
            time: 'just now',
            icon: 'plus_one',
            color: 'primary',
            start: event.start,
            end: event.end,
            userId: event.requestUserId,
            isApproved: event.isApproved,
          };
          //this.events.push(event);
          this.notificationService.add(notification);
        });

      }
    });

  }

  deleteEvent(id: string) {
    this.document(`/${id}`).delete();
  }

  // addNotification(notification: Notification) {
  //   this.db.col("notifications").add(notification).then(result => {
  //     console.log('saved notification', result.id);
  //   });
  // }

  public isAuthenticated() {

    // Acts immediately whenever the user is already logged-in
    if (this.authenticated) {
      //console.log(this.authenticated);
      return true;
    }

    // Proceed to authenticate the user otherwise
    else {
      this.guard.authenticate().then(user => {
        console.log('user', user);
        // Stops on authentication failed/aborted
        if (!user) { return false; }

      });
    }
  }

  /** Checks if the specified userId is among the likers */
  private isLikedBy(userId: string): Observable<boolean> {

    // Searches among the collection of likers 
    return this.patients
      // Matches for the document named upon the userId
      .stream(ref => ref.where(this.db.sentinelId, "==", userId))
      // Returns true if such document exists
      .pipe(map(docs => docs.length > 0));
  }

  /** Toggles the favorite status */
  public toggleFavorite() {

    // Acts immediately whenever the user is already logged-in
    if (this.authenticated) { this.updateFavorite(true, this.me); }

    // Proceed to authenticate the user otherwise
    else {
      this.guard.authenticate().then(user => {

        // Stops on authentication failed/aborted
        if (!user) { return false; }
        // Checks the user against the likers
        return this.isLikedBy(user.uid).pipe(
          // Gets the first results and completes
          take(1),
          // Updates the favorite once the authentication succeeded
          tap(favorite => this.updateFavorite(!favorite, user.uid))
        ).toPromise();
      });
    }
  }

  /** Updates the favorite status */
  private updateFavorite(patient: boolean, user: string) {

    // Updates the local favorite flag copy for improved reactivity
    this._patient$.next(patient);

    // Adds the user to the collection of likers....
    if (patient) { this.patients.document(user).set({}); }
    // ...or removes it according to the request
    else { this.patients.document(user).delete(); }
  }

}
