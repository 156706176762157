import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { ThemeModule } from 'theme';
import { HomeComponent } from './home.component';
import { DashboardModule } from 'app/pages/dashboard';
import { MapsModule } from 'app/pages/maps';
import { OrtodentServicesComponent } from './ortodent-services/ortodent-services.component';
import { ScheduleModule } from 'app/schedule';
import { PricesComponent } from './prices/prices.component';
import { ProfilaxieComponent } from './profilaxie/profilaxie.component';
import { UrgenteComponent } from './urgente/urgente.component';
import { ProteticaComponent } from './protetica/protetica.component';
import { EndodontieComponent } from './endodontie/endodontie.component';
import { OdontoterapieComponent } from './odontoterapie/odontoterapie.component';
import { OrtodontieComponent } from './ortodontie/ortodontie.component';
import { PedodontieComponent } from './pedodontie/pedodontie.component';
import { ChirurgieComponent } from './chirurgie/chirurgie.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleComponent } from './articles/article/article.component';
// import { HomeRoutingModule } from './home-routing.module';

@NgModule({
  declarations: [
    HomeComponent,
    OrtodentServicesComponent,
    PricesComponent,
    ProfilaxieComponent,
    UrgenteComponent,
    ProteticaComponent,
    EndodontieComponent,
    OdontoterapieComponent,
    OrtodontieComponent,
    PedodontieComponent,
    ChirurgieComponent,
    ArticlesComponent,
    ArticleComponent,
  ],
  imports: [
    CommonModule,
    ThemeModule,
    DashboardModule,
    MapsModule,
    ScheduleModule,
    RouterModule
  ],
  exports: [HomeComponent, PricesComponent,],
})
export class HomeModule { }