import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { AngularFireDatabase, AngularFireObject, AngularFireAction } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
//import 'rxjs/add/operator/switchMap';
//import 'rxjs/add/observable/of';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// import { User } from '../models/user'
import { switchMap } from 'rxjs/operators';

import { AuthService, DatabaseService, DatabaseCollection, dbCommon, User } from 'app/connect';
import { dbUser } from 'app/connect/auth/user-profile.service';
// import { AuthService } from '../auth/auth.service'


@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent extends DatabaseCollection<dbUser> implements AfterViewInit, OnDestroy {

  //users: Observable<any[]>;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['select', 'name', 'email', 'group', 'role', 'status', 'actions'];
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // users$: Observable<dbUser[]>;
  // size$: BehaviorSubject<string | null>;
  private subscription: Subscription;

  constructor(
    // private rtdb: AngularFireDatabase,
    db: DatabaseService,
    private auth: AuthService,
    private router: Router,
    public dialog: MatDialog) {

    super(db, db.col('users'));

    this.stream(ref => ref.orderBy('name', 'asc')).pipe().subscribe(response => {
      const users = response;
      // this.users$= users;
      this.dataSource.data = users;
    });



    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // filterBy(size: string | null) {
  //   this.size$.next(size);
  // }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startEdit(user: dbUser) {
    console.log('user', user);
    localStorage.setItem('editingUserId', user.id);
    this.router.navigate(['app/user/', user.id]);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
        // this.qqq = row.Id;
      });
  }

  toggleSelect(row) {
    this.selection.toggle(row);
    this.selection.selected.forEach(x => {
      console.log(x);
    });
  }
}