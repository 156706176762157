import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ThemeModule } from 'theme';
import { EchipamenteComponent } from './echipamente.component';
import { EchipamentComponent } from './echipament/echipament.component';
import { EditEchipamentComponent } from './edit-echipament/edit-echipament.component';
import { MaterialAngularSelectModule } from 'material-angular-select';
import { MatCardModule, MatTableModule, MatPaginatorModule, MatCheckboxModule, MatProgressBarModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { AddEchipamentComponent } from './add-echipament/add-echipament.component';

// Define navigation routes
const routes: Routes = [

  // { path: 'add', component: AddEchipamentComponent }

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    RouterModule,
    MaterialAngularSelectModule,
    MatCardModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatProgressBarModule,
    // RouterModule.forChild(routes),
  ],
  declarations: [
    EchipamenteComponent,
    EchipamentComponent,
    EditEchipamentComponent,
    AddEchipamentComponent
  ],
  // exports: [ RouterModule ]
})
export class EchipamenteModule { }