import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DatabaseCollection, DatabaseService, DatabaseDocument } from 'app/connect';
import { dbUser } from 'app/connect/auth/user-profile.service';
import { Observable } from 'rxjs';
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { Router } from '@angular/router';
import { dbPatient } from './patient.model';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Utilities } from 'app/services/utilities';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationDialog } from 'app/dialogs/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent extends DatabaseCollection<dbPatient> implements OnInit, AfterViewInit, OnDestroy {

  // readonly users$: Observable<dbUser[]>;
  // private data: dbUser;

  dataSource = new MatTableDataSource<any>();

  displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'cnp', 'actions'];
  selection = new SelectionModel<any>(true, []);
  rowsCache: dbPatient[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /** Returns the current authenticated userId or 'unknown' */
  get me(): string { return this.guard.userId || 'unknown'; }
  get role(): string { return localStorage.getItem('userRole') };


  @ViewChild('dialogModal') dialogModal: ModalDirective;
  isModalShown = false;

  constructor(
    private guard: AuthGuard,
    db: DatabaseService,
    private router: Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar) {
    // Constructs the DatabseColleciton referring to 'places'
    super(db, db.col('patients'));
    // Streams the array of patients ordering them by ascending names 
    // this.users$ = this.stream(ref => ref.orderBy('name', 'asc'));

    this.stream(ref => ref.orderBy('firstName', 'asc')).pipe().subscribe(response => {
      const users = response;
      // this.users$= users;
      this.dataSource.data = users;
      this.rowsCache = [...users];
      console.log('patients', response);
      if (users.length == 0) {
        this.createDumyPatients();
      }

    });



    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    switch (this.role) {
      case 'developer':
        this.displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'cnp', 'password', 'actions'];
        break;
      case 'admin':
        this.displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'cnp', 'actions'];
        break;
      default:
        this.displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'cnp', 'actions'];
        break;
    }

    // if (this.role == 'admin') {
    //   this.displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'password', 'actions'];
    // }
    // else {
    //   this.displayedColumns = ['select', 'firstName', 'lastName', 'email', 'birthDate', 'gender', 'userName', 'actions'];
    // }

  }

  ngAfterViewInit() {
    // this.orderEditor.changesSavedCallback = () => {
    //   this.addNewUserToList();
    //   this.editorModal.hide();
    // };

    // this.orderEditor.changesCancelledCallback = () => {
    //   this.editedOrder = null;
    //   this.sourceOrder = null;
    //   this.editorModal.hide();
    // };
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  createDumyPatients() {

    const patients = <dbPatient[]>
      [
        {
          "firstName": "Hannah",
          "lastName": "Lee",
          "gender": "Female",
          "streetAddress": "45 Harding Highway",
          "cityName": "Cleveland",
          "stateName": "MS",
          "zipCode": "56582",
          "phoneNumber": "9744640599",
          "birthDate": "30-11-1997",
          "userName": "hannahlee",
          "password": "N#53htyQ",
          "email": "hlee968@eelhannah.net"
        },
        {
          "firstName": "Dean",
          "lastName": "Collins",
          "gender": "Male",
          "streetAddress": "456 East Buchanan Place",
          "cityName": "Yonkers",
          "stateName": "WI",
          "zipCode": "65836",
          "phoneNumber": "2268456953",
          "birthDate": "08-06-2013",
          "userName": "deancollins",
          "password": "J4cg2Gj$",
          "email": "dcollins301@snillocnaed.org"
        },
        {
          "firstName": "Brenna",
          "lastName": "Salazar",
          "gender": "Female",
          "streetAddress": "663 Hill Drive",
          "cityName": "San Francisco",
          "stateName": "WA",
          "zipCode": "01537",
          "phoneNumber": "8982759300",
          "birthDate": "26-11-1957",
          "userName": "brennasalazar",
          "password": "Q42q#Brg",
          "email": "bsalazar681@razalasannerb.com"
        },
        {
          "firstName": "Clarissa",
          "lastName": "Conner",
          "gender": "Female",
          "streetAddress": "949 Taylor Alley",
          "cityName": "Albuquerque",
          "stateName": "MD",
          "zipCode": "77841",
          "phoneNumber": "5582269379",
          "birthDate": "17-02-2006",
          "userName": "clarissaconner",
          "password": "Gw1no2V@",
          "email": "cconner95@rennocassiralc.biz"
        },
        {
          "firstName": "Bria",
          "lastName": "Vega",
          "gender": "Female",
          "streetAddress": "964 Madison Highway",
          "cityName": "Centennial",
          "stateName": "NM",
          "zipCode": "89455",
          "phoneNumber": "4744884893",
          "birthDate": "30-09-1959",
          "userName": "briavega",
          "password": "D%zrhT65",
          "email": "bvega392@agevairb.biz"
        },
        {
          "firstName": "Kayleigh",
          "lastName": "Rodgers",
          "gender": "Female",
          "streetAddress": "888 Nixon Avenue",
          "cityName": "Fontana",
          "stateName": "OK",
          "zipCode": "92825",
          "phoneNumber": "4727644423",
          "birthDate": "17-12-1944",
          "userName": "kayleighrodgers",
          "password": "N8Mbtw$2",
          "email": "krodgers339@sregdorhgielyak.org"
        },
        {
          "firstName": "Arielle",
          "lastName": "Riley",
          "gender": "Female",
          "streetAddress": "420 1st Way",
          "cityName": "Columbus",
          "stateName": "MD",
          "zipCode": "21126",
          "phoneNumber": "4758630420",
          "birthDate": "19-10-1949",
          "userName": "arielleriley",
          "password": "D0#6jBoi",
          "email": "ariley523@yelirelleira.biz"
        },
        {
          "firstName": "Carina",
          "lastName": "Rojas",
          "gender": "Female",
          "streetAddress": "194 South Pine Alley",
          "cityName": "Oxford",
          "stateName": "IL",
          "zipCode": "82081",
          "phoneNumber": "3346140093",
          "birthDate": "08-08-1930",
          "userName": "carinarojas",
          "password": "I5lxh8%J",
          "email": "crojas485@sajoranirac.net"
        },
        {
          "firstName": "Ernest",
          "lastName": "Hamilton",
          "gender": "Male",
          "streetAddress": "348 Springhill Alley",
          "cityName": "Lansing",
          "stateName": "WI",
          "zipCode": "07555",
          "phoneNumber": "8765276721",
          "birthDate": "04-04-1985",
          "userName": "ernesthamilton",
          "password": "R3W2lee%",
          "email": "ehamilton618@notlimahtsenre.biz"
        },
        {
          "firstName": "Blanca",
          "lastName": "Lopez",
          "gender": "Female",
          "streetAddress": "60 North Washington Alley",
          "cityName": "Ann Arbor",
          "stateName": "RI",
          "zipCode": "25860",
          "phoneNumber": "8775954260",
          "birthDate": "21-08-1937",
          "userName": "blancalopez",
          "password": "TU5b#2jw",
          "email": "blopez845@zepolacnalb.net"
        },
        {
          "firstName": "Lane",
          "lastName": "Patterson",
          "gender": "Male",
          "streetAddress": "335 Hoover Boulevard",
          "cityName": "San Francisco",
          "stateName": "AZ",
          "zipCode": "43478",
          "phoneNumber": "8997054922",
          "birthDate": "11-02-1942",
          "userName": "lanepatterson",
          "password": "B1@5jnsC",
          "email": "lpatterson515@nosrettapenal.net"
        },
        {
          "firstName": "Roy",
          "lastName": "Wilson",
          "gender": "Male",
          "streetAddress": "742 Grant Avenue",
          "cityName": "Miramar",
          "stateName": "CA",
          "zipCode": "56943",
          "phoneNumber": "3648015858",
          "birthDate": "17-01-1945",
          "userName": "roywilson",
          "password": "C#6qXde8",
          "email": "rwilson349@nosliwyor.org"
        },
        {
          "firstName": "Daniella",
          "lastName": "Harrison",
          "gender": "Female",
          "streetAddress": "586 Hayes Boulevard",
          "cityName": "Fort Worth",
          "stateName": "UT",
          "zipCode": "74808",
          "phoneNumber": "4022070942",
          "birthDate": "26-06-1948",
          "userName": "daniellaharrison",
          "password": "H8@ei6Nl",
          "email": "dharrison111@nosirrahalleinad.com"
        },
        {
          "firstName": "Emilio",
          "lastName": "Harvey",
          "gender": "Male",
          "streetAddress": "437 7th Lane",
          "cityName": "McAllen",
          "stateName": "CO",
          "zipCode": "90590",
          "phoneNumber": "3418833474",
          "birthDate": "23-04-1992",
          "userName": "emilioharvey",
          "password": "DlK0$fq9",
          "email": "eharvey570@yevrahoilime.org"
        },
        {
          "firstName": "Angelo",
          "lastName": "Aguilar",
          "gender": "Male",
          "streetAddress": "658 Watermelon Alley",
          "cityName": "Visalia",
          "stateName": "WV",
          "zipCode": "47643",
          "phoneNumber": "9634972232",
          "birthDate": "31-05-1955",
          "userName": "angeloaguilar",
          "password": "X#oyhJ57",
          "email": "aaguilar782@raliugaolegna.biz"
        },
        {
          "firstName": "Darren",
          "lastName": "Lambert",
          "gender": "Male",
          "streetAddress": "93 Kiwi Way",
          "cityName": "Honolulu",
          "stateName": "TN",
          "zipCode": "67534",
          "phoneNumber": "9984139254",
          "birthDate": "22-12-1923",
          "userName": "darrenlambert",
          "password": "YE83nss@",
          "email": "dlambert17@trebmalnerrad.org"
        },
        {
          "firstName": "Jillian",
          "lastName": "Holland",
          "gender": "Female",
          "streetAddress": "134 Grapefruit Terrace",
          "cityName": "Garland",
          "stateName": "IA",
          "zipCode": "82455",
          "phoneNumber": "7017307224",
          "birthDate": "07-08-1949",
          "userName": "jillianholland",
          "password": "Tz$qwU32",
          "email": "jholland868@dnallohnaillij.org"
        },
        {
          "firstName": "Giovanni",
          "lastName": "Wells",
          "gender": "Male",
          "streetAddress": "409 Hawthorn Circle",
          "cityName": "Westminster",
          "stateName": "NY",
          "zipCode": "55076",
          "phoneNumber": "7425002765",
          "birthDate": "26-01-1951",
          "userName": "giovanniwells",
          "password": "VuL#1ob9",
          "email": "gwells957@sllewinnavoig.com"
        },
        {
          "firstName": "Marshall",
          "lastName": "Malone",
          "gender": "Male",
          "streetAddress": "73 West Poplar Drive",
          "cityName": "Topeka",
          "stateName": "ME",
          "zipCode": "53907",
          "phoneNumber": "9927195360",
          "birthDate": "15-08-1921",
          "userName": "marshallmalone",
          "password": "Yo5Kfe#0",
          "email": "mmalone121@enolamllahsram.net"
        },
        {
          "firstName": "Damon",
          "lastName": "Craig",
          "gender": "Male",
          "streetAddress": "910 Alder Way",
          "cityName": "Corpus Christi",
          "stateName": "NH",
          "zipCode": "24518",
          "phoneNumber": "6602894073",
          "birthDate": "02-11-1995",
          "userName": "damoncraig",
          "password": "UP3k#8zf",
          "email": "dcraig102@giarcnomad.net"
        },
        {
          "firstName": "Kendra",
          "lastName": "Acosta",
          "gender": "Female",
          "streetAddress": "703 South Hawthorn Garden",
          "cityName": "Lewisville",
          "stateName": "MD",
          "zipCode": "89337",
          "phoneNumber": "7613275797",
          "birthDate": "11-11-1990",
          "userName": "kendraacosta",
          "password": "G29b#rhU",
          "email": "kacosta645@atsocaardnek.net"
        },
        {
          "firstName": "Kelley",
          "lastName": "Olson",
          "gender": "Female",
          "streetAddress": "676 East Watermelon Street",
          "cityName": "North Las Vegas",
          "stateName": "TN",
          "zipCode": "39478",
          "phoneNumber": "7994957201",
          "birthDate": "28-10-1947",
          "userName": "kelleyolson",
          "password": "G63edrX@",
          "email": "kolson385@nosloyellek.org"
        },
        {
          "firstName": "Keaton",
          "lastName": "Rios",
          "gender": "Male",
          "streetAddress": "609 1st Lane",
          "cityName": "Ontario",
          "stateName": "VT",
          "zipCode": "50990",
          "phoneNumber": "7878287408",
          "birthDate": "13-11-1930",
          "userName": "keatonrios",
          "password": "VqxL%l05",
          "email": "krios181@soirnotaek.biz"
        },
        {
          "firstName": "Ashton",
          "lastName": "Lyons",
          "gender": "Female",
          "streetAddress": "838 Lime Avenue",
          "cityName": "Mesa",
          "stateName": "NV",
          "zipCode": "24033",
          "phoneNumber": "3912191437",
          "birthDate": "10-05-1995",
          "userName": "ashtonlyons",
          "password": "L%si7V3l",
          "email": "alyons792@snoylnothsa.biz"
        },
        {
          "firstName": "Zackery",
          "lastName": "Nunez",
          "gender": "Male",
          "streetAddress": "765 North 6th Lane",
          "cityName": "Kingston",
          "stateName": "NE",
          "zipCode": "32833",
          "phoneNumber": "9245335699",
          "birthDate": "17-06-1936",
          "userName": "zackerynunez",
          "password": "Zarx6E@8",
          "email": "znunez893@zenunyrekcaz.net"
        },
        {
          "firstName": "Russell",
          "lastName": "Marquez",
          "gender": "Male",
          "streetAddress": "20 Jackson Lane",
          "cityName": "Honolulu",
          "stateName": "MT",
          "zipCode": "31395",
          "phoneNumber": "8738332324",
          "birthDate": "21-04-1956",
          "userName": "russellmarquez",
          "password": "Ir3laR#8",
          "email": "rmarquez520@zeuqramllessur.org"
        },
        {
          "firstName": "Micheal",
          "lastName": "Smith",
          "gender": "Male",
          "streetAddress": "89 Sunrise Turnpike",
          "cityName": "Fullerton",
          "stateName": "AZ",
          "zipCode": "46405",
          "phoneNumber": "4527162119",
          "birthDate": "06-09-1925",
          "userName": "michealsmith",
          "password": "Iw8dG5#d",
          "email": "msmith691@htimslaehcim.org"
        },
        {
          "firstName": "Rachel",
          "lastName": "Malone",
          "gender": "Female",
          "streetAddress": "131 West 7th Way",
          "cityName": "Dallas",
          "stateName": "AK",
          "zipCode": "43367",
          "phoneNumber": "7918626418",
          "birthDate": "03-08-1955",
          "userName": "rachelmalone",
          "password": "LnKl%00u",
          "email": "rmalone936@enolamlehcar.com"
        },
        {
          "firstName": "Kristopher",
          "lastName": "Bennett",
          "gender": "Male",
          "streetAddress": "581 East Kiwi Place",
          "cityName": "Frisco",
          "stateName": "MT",
          "zipCode": "31104",
          "phoneNumber": "5022638817",
          "birthDate": "28-09-1971",
          "userName": "kristopherbennett",
          "password": "U08ee&Ou",
          "email": "kbennett689@ttennebrehpotsirk.biz"
        },
        {
          "firstName": "Shana",
          "lastName": "Bell",
          "gender": "Female",
          "streetAddress": "19 Pine Loop",
          "cityName": "Los Angeles",
          "stateName": "HI",
          "zipCode": "63862",
          "phoneNumber": "3735554222",
          "birthDate": "27-11-1974",
          "userName": "shanabell",
          "password": "B5%0Kzaa",
          "email": "sbell226@llebanahs.org"
        }
      ]

    patients.forEach(element => {
      this.ref.add(element);
    });


  }

  startEdit(user: dbPatient) {
    console.log('user', user);
    localStorage.setItem('editingUserId', user.id);
    // this.router.navigate(['app/patients/', user.id]);
    this.router.navigate(['app/patient']);
  }

  deleteItem(user: dbPatient) {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: `Sunteti sigur?`,
        buttonText: {
          ok: 'Accept',
          cancel: 'Nu'
        }
      }
    });

    const snack = this.snackBar.open(`Eliminare pacient: ${user.userName}`);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        snack.dismiss();
        const a = document.createElement('a');
        a.click();
        a.remove();

        this.document(user.id).delete().then(res => {
          console.log('removed', user);
          snack.dismiss();
          this.snackBar.open('Produsul a fost eliminat', user.userName, {
            duration: 2000,
          });
        }).catch(error => console.error("Error removing document: ", error))


        // snack.dismiss();
        // this.snackBar.open('Closing snack bar in a few seconds', 'Fechar', {
        //   duration: 2000,
        // });
      }
      snack.dismiss();
    });

    this.dialogModal.show();
    this.document(user.id).delete().then(res => {
      console.log('removed', user);
    }).catch(error => console.error("Error removing document: ", error))
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
        // this.qqq = row.Id;
      });
  }

  toggleSelect(row) {
    this.selection.toggle(row);
    this.selection.selected.forEach(x => {
      console.log(x);
    });
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.dialogModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  onSearchChanged(value: string) {
    this.dataSource.data = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.firstName, r.lastName));
  }

  createPatient() {
    const p = <dbPatient>{
      "firstName": "",
      "lastName": "",
      "gender": "",
      "streetAddress": "",
      "cityName": "",
      "stateName": "",
      "zipCode": "",
      "phoneNumber": "",
      "birthDate": "",
      "userName": "",
      "password": "N#",
      "email": ""
    }

    this.add(p).then(docRef => {
      console.log("'patient created: ", docRef.ref.id);
      localStorage.setItem('editingUserId', docRef.ref.id);
      localStorage.setItem('isNewPatient', 'true');
      // this.router.navigate([`/app/patients/`, (<any>docRef).ref.id]);
      this.router.navigate([`/app/patient`]);
    })
      .catch(error => console.error("Error adding document: ", error))
  }

}
