import { Component, OnInit } from '@angular/core';
import { DatabaseCollection, dbCommon, DatabaseService } from 'app/connect';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

export interface dbArticle extends dbCommon {
  title?: string,
  content?: string,
  image?: string
}

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends DatabaseCollection<dbArticle> implements OnInit {

  articles$: Observable<dbArticle[]>;

  constructor(db: DatabaseService, private router: Router) {
    // Constructs the DatabseColleciton referring to 'articles'
    super(db, db.col('home'));

    // Streams the array of places ordering them by ascending names 
    this.articles$ = this.stream();
    
  }

  ngOnInit() {
    this.articles$.subscribe(res=>{
      console.log('articles', res);
    });
  }

  navigate(article: any) {
    localStorage.setItem('article', article.id);
    this.router.navigate(["app/article"])
  }

}
