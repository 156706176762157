import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { dbCommon, DatabaseDocument, DatabaseService, AuthService, StorageService, UploadTask } from 'app/connect';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { dbUser, UserProfile } from 'app/connect/auth/user-profile.service';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-user-form',
  styleUrls: ['./user-form.component.scss'],
  templateUrl: 'user-form.component.html',
})
export class UserFormComponent extends DatabaseDocument<dbUser> implements OnInit, OnDestroy {
  @HostBinding('class.employer-form') private readonly employerForm = true;
  public roles = [
    { 'title': 'User', 'value': 'user' },
    { 'title': 'Doctor', 'value': 'doctor' },
    { 'title': 'Administrator', 'value': 'admin' },
    { 'title': 'Developer', 'value': 'developer' }
  ];

  user$: dbUser = null;
  isAdmin: boolean = true;
  readonly photo$: Observable<string>;

  constructor(
    // private rtdb: AngularFireDatabase,
    db: DatabaseService,
    private auth: AuthService,
    // private router: Router,
    // public dialog: MatDialog
    private route: ActivatedRoute,
    private profile: UserProfile, 
    private storage: StorageService, 
    private builder: FormBuilder) {


    // super(db, db.doc(`users/${'ryUCGn1t60SWWkWeNnToWfPFGm32'}`));
    super(db, db.doc(`users/${localStorage.getItem('editingUserId')}`));

    // localStorage.getItem('editingUserId');

    this.stream().pipe().subscribe(response => {
      console.log('user', response);
      this.user$ = response;
    });

    // this.profile.get().then(data => {
    //   console.log('user', data);
    //   this.user$ = data
    // });

    // Streams the profile photo
    // this.photo$ = this.profile.stream().pipe(map(profile => !!profile ? profile.photo : ''));
    this.photo$ = this.stream().pipe(map(profile => !!profile ? profile.photo : ''));
  }

  get editingSelf(): boolean {
    return localStorage.getItem('editingUserId') == this.profile.uid;
  }

  ngOnInit() {
    // this.route.params.pipe(
    //   switchMap((params: Params) => this.productService.getFood(params['uid']))
    //   .subscribe((food: Food) => {
    //     this.food = food;
    //   }));

    // this.isAdmin = this.auth.isAdmin();
    // let userId = this.auth.userId;
    // console.log('isAdmin', this.auth.getRoles(), this.isAdmin);
    // // this.likeDisabled = false;

  }

  ngOnDestroy() {
    localStorage.removeItem("editingUserId");
  }

  roleSelected(role) {
    this.user$.role = role;
  }

  saveUser(user) {
    this.update(user);
  }

  public uploadTask: UploadTask;

  public uploadPhoto(file: File): Promise<void> {

    if (!file) { return Promise.resolve(null); }

    // Creates the uploading task, this will display the progress bar in the view
    return (this.uploadTask = this.storage.upload(`${this.auth.userId}/${file.name}`, file))
      // Returns the url
      .then(snap => snap.ref.getDownloadURL())
      // Updates the profile with the new url
      .then(photo => this.profile.update({ photo }))
      // Deletes the task object removing the progress bar from the view
      .then(() => (delete this.uploadTask, null));
  }

  public deletePhoto(): Promise<void> {

    // Deletes the file in the storage first
    return this.deleteFile()
      // Resets the photo url into the profile
      .then(() => this.profile.update({ photo: '' }));
  }

  private deleteFile(): Promise<void> {
    // Reads the profile to get the photo url
    return this.profile.get().then(profile => {
      // Skips then no file
      if (!profile || !profile.photo) { return null; }
      // Gets the storage ref from the url...
      const ref = this.storage.refFromURL(profile.photo);
      //... and deletes the file
      return ref.delete();
    })
      // Ensure to proceed whatever error has been encountered
      .catch(e => null);
  }
}
