import { Component, OnInit } from '@angular/core';
import { dbUser } from 'app/connect/auth/user-profile.service';
import { DatabaseService, DatabaseCollection, dbCommon } from '../connect';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent extends DatabaseCollection<dbUser> implements OnInit {

  readonly users$: Observable<dbUser[]>;

  constructor(db: DatabaseService) {
    // Constructs the DatabseColleciton referring to 'places'
    super(db, db.col('users'));
    // Streams the array of places ordering them by ascending names 
    this.users$ = this.stream(ref => ref.where('role.value', '==', 'doctor').orderBy('name', 'asc'));
  }

  ngOnInit() {
  }

}
