import { Injectable, OnInit } from '@angular/core';
import {
    CalendarSchedulerEvent,
    CalendarSchedulerEventStatus,
    CalendarSchedulerEventAction
} from 'angular-calendar-scheduler';
import {
    addDays,
    startOfHour,
    addHours,
    setHours,
    subMinutes
} from 'date-fns';
import { DatabaseCollection, DatabaseService } from 'app/connect';
import { dbEvent } from './schedule.model';
import { Observable, } from 'rxjs';
import { firestore, auth } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { map } from 'rxjs/operators';

@Injectable()
export class ScheduleService extends DatabaseCollection<dbEvent> implements OnInit {

    private startDate = Timestamp.fromDate(new Date(2019, 12, 1));
    constructor(
        db: DatabaseService,
    ) {
        super(db, db.col('schedules'));

    }

    ngOnInit(): void {
        //https://github.com/mounthorse-slns/angular-calendar-scheduler/blob/master/src/app/services/app.service.ts
    }
    getEvents(actions: CalendarSchedulerEventAction[]): Promise<CalendarSchedulerEvent[]> {
        const events = [
            <CalendarSchedulerEvent>{
                id: '1',
                start: addDays(startOfHour(new Date()), 1),
                end: addDays(addHours(startOfHour(new Date()), 1), 1),
                title: 'Event 1',
                content: 'IMPORTANT EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'danger' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                }
            },
            <CalendarSchedulerEvent>{
                id: '2',
                start: addDays(startOfHour(new Date()), 2),
                end: subMinutes(addDays(addHours(startOfHour(new Date()), 2), 2), 15),
                title: 'Event 2',
                content: 'LESS IMPORTANT EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'warning' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '3',
                start: addDays(startOfHour(new Date()), 3),
                end: addDays(addHours(startOfHour(new Date()), 3), 3),
                title: 'Event 3',
                content: 'NOT IMPORTANT EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '4',
                start: startOfHour(addHours(new Date(), 2)),
                end: addHours(startOfHour(addHours(new Date(), 2)), 2),
                title: 'Event 4',
                content: 'TODAY EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '5',
                start: addDays(startOfHour(setHours(new Date(), 6)), 2),
                end: addHours(addDays(startOfHour(setHours(new Date(), 6)), 2), 1),
                title: 'Event 5',
                content: 'EARLY EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '6',
                start: startOfHour(setHours(new Date(), 22)),
                end: addHours(startOfHour(setHours(new Date(), 22)), 10),
                title: 'Event 6',
                content: 'TWO DAYS EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '7',
                start: addDays(startOfHour(setHours(new Date(), 14)), 4),
                end: addDays(addDays(startOfHour(setHours(new Date(), 14)), 4), 2),
                title: 'Event 7',
                content: 'THREE DAYS EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            },
            <CalendarSchedulerEvent>{
                id: '8',
                start: startOfHour(addHours(new Date(), 2)),
                end: addHours(startOfHour(addHours(new Date(), 2)), 3),
                title: 'Event 8',
                content: 'CONCURRENT EVENT',
                color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                actions: actions,
                status: 'ok' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false
            }
        ];

        return new Promise(resolve => setTimeout(() => resolve(events), 3000));
    }

    getDbEvents(actions: CalendarSchedulerEventAction[]): Observable<CalendarSchedulerEvent[]> {
        // return this.stream(ref => ref.where('start', '>=', Date.parse('2019-01-01'))).pipe(map((res: any) => {
        return this.stream(ref => ref.orderBy('start', 'desc').startAfter(this.startDate)).pipe(map((res: any) => {
            res.forEach((e, index) => {
                e.start = (<Timestamp>e.start).toDate();
                e.end = (<Timestamp>e.end).toDate();
                e.actions = actions;
                e.index = index;
            })
            return res;
        }))//.subscribe(e => {
        // console.log('events', e);
        // return e;
    };//);

    getDbEventsForTarget(actions: CalendarSchedulerEventAction[], userId: string): Observable<CalendarSchedulerEvent[]> {
        return this.stream(ref => ref.where('targetUserId', '==', userId).orderBy('start', 'desc').startAfter(this.startDate)).pipe(map((res: any) => {
            res.forEach((e, index) => {
                e.start = (<Timestamp>e.start).toDate();
                e.end = (<Timestamp>e.end).toDate();
                e.actions = actions;
                e.index = index;
            })
            return res;
        }))
    };

    getDbEventsForRequestor(actions: CalendarSchedulerEventAction[], userId: string): Observable<CalendarSchedulerEvent[]> {
        return this.stream(ref => ref.where('requestUserId', '==', userId)
            .orderBy('start', 'desc')
            // .startAfter(this.startDate)
        ).pipe(map((res: any) => {
            res.forEach((e, index) => {
                e.start = (<Timestamp>e.start).toDate();
                e.end = (<Timestamp>e.end).toDate();
                e.actions = actions;
                e.index = index;
            })
            return res;
        }))
    };


    deleteEvent(id: string) {
        this.document(`/${id}`).delete();
    }

    updateEvent(event: dbEvent) {
        event.actions = null;
        (<any>event).index = null;
        console.log('updating event', event);
        // console.log('updating event', this.db.doc(`schedules/${event.id}`));


        this.db.doc(`schedules/${event.id}`).set(event).then(res => {
            console.log('updated event', event);
        }).catch(error => {
            console.log('error updating event', error);
        });


        // this.db.doc(`schedules/${event.id}`).update(event).then(result => {
        //     console.log('saved event', result);
        //     //this.events.push(event);
        //   });
    }
}