import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { DatabaseDocument, DatabaseService, AuthService } from 'app/connect';
import { ActivatedRoute, } from '@angular/router';
import { Location } from '@angular/common';
import { dbProduct } from '../echipament/echipament.component';

@Component({
  selector: 'app-add-echipament',
  templateUrl: './add-echipament.component.html',
  styleUrls: ['./add-echipament.component.scss']
})
export class AddEchipamentComponent extends DatabaseDocument<dbProduct> implements OnInit, OnDestroy {
  @HostBinding('class.employer-form') private readonly productForm = true;
  public categories = ['ANESTEZIE', 'AMPRENTARE', 'PROTECTIE SI DEZINFECTIE', 'CHIRURGIE', 'ECHIPAMENT'];

  product$: dbProduct;


  constructor(
    db: DatabaseService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private location: Location
  ) {

    super(db, db.doc(`stock/${localStorage.getItem('editingProductId')}`));

    this.stream().pipe().subscribe(response => {
      console.log('product', response);
      this.product$ = response;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    localStorage.removeItem("editingProductId");
  }

  categorySelected(category) {
    this.product$.category = category.value != undefined ? category.value : category;
  }

  saveProduct(product) {
    this.update(product);
    this.location.back();
  }

  back() {
    this.location.back();
  }

}
