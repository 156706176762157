import { Injectable, OnInit, Inject, LOCALE_ID, AfterViewInit } from '@angular/core';
import { DatabaseCollection, DatabaseService, AuthService } from 'app/connect';
import { dbEvent } from 'app/schedule/schedule.model';
import { Observable } from 'rxjs';
import { firestore, auth } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { map, switchMap } from 'rxjs/operators';
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { Notification, dbNotification } from './notification.model';


@Injectable()
export class NotificationMenuService extends DatabaseCollection<dbNotification> implements OnInit, AfterViewInit {

  notifications$: Observable<Notification[]>;
  // notifications$: Notification[] = [];
  uid;

  private role;

  /** Returns true whenever the current user is authenticated */
  get authenticated(): boolean { return this.guard.authenticated; }
  /** Returns the current authenticated userId or 'unknown' */
  get me(): string { return this.guard.userId || 'unknown'; }

  constructor(
    @Inject(LOCALE_ID) locale: string,
    db: DatabaseService,
    private guard: AuthGuard,
    // private dateAdapter: DateAdapter,
    // private appService: ScheduleService,
    // private dialog: MatDialog,
    private auth: AuthService
  ) {

    // Constructs the DatabseColleciton referring to 'places'
    // super(db, db.col('schedules'));
    // // Streams the array of places ordering them by ascending names 
    // this.stream(ref => ref.orderBy('name', 'asc')).pipe().subscribe(events => {
    //   this.events = events;
    //   console.log('events', events);
    // });

    super(db, db.col('notifications'));
    // this.events$ = this.stream(ref => ref.orderBy('name', 'asc'));

    // this.auth.authState$.subscribe(x => {
    //   console.log('wefe', x.uid, x.email);
    //   console.log('me', this.me);


    // });

    this.role = localStorage.getItem('userRole');

    this.auth.authState$.subscribe(x => {
      this.uid = guard.userId;
      localStorage.setItem('uid', this.uid);



    });

    var startDate = Timestamp.fromDate(new Date(2019, 12, 1));
    this.notifications$ = this.stream(ref => ref.orderBy("start").startAfter(startDate)
      //.where('isApproved', '==', false)
      .where('userId', '==', localStorage.getItem('uid'))
    ).pipe(map((res: any) => {
      console.log('uid', this.uid);
      console.log('res', res);
      res.forEach(e => {
        e.start = (<Timestamp>e.start).toDate();
        e.end = (<Timestamp>e.end).toDate();
      })
      // return res.map(element => {
      //   const notification = <Notification>{
      //     text: element.title,
      //     time: 'just now',
      //     icon: 'plus_one',
      //     color: 'primary',
      //   };
      //   return notification;
      // });
      return res;
    }));



    // // var startDate = new Timestamp(1576800000, 0);
    // var startDate = Timestamp.fromDate(new Date(2019, 12, 1));
    // console.log('startDate', startDate);
    // switch (this.role) {
    //   case 'admin':
    //     this.notifications$ = this.stream(ref => ref.orderBy("start").startAfter(startDate)
    //       .where('isApproved', '==', false)
    //       // .where('targetUserId', '==', this.uid)
    //     ).pipe(map((res: any) => {
    //       console.log('uid', this.uid);
    //       res.forEach(e => {
    //         e.start = (<Timestamp>e.start).toDate();
    //         e.end = (<Timestamp>e.end).toDate();
    //       })
    //       return res.map(element => {
    //         const notification = <Notification>{
    //           text: element.title,
    //           time: 'just now',
    //           icon: 'plus_one',
    //           color: 'primary',
    //         };
    //         return notification;
    //       });
    //     }));
    //     break;

    //   case 'doctor':
    //     this.notifications$ = this.stream(ref => ref.orderBy("start").startAfter(startDate)
    //       //.where('isApproved', '==', false)
    //       .where('targetUserId', '==', localStorage.getItem('uid'))
    //     ).pipe(map((res: any) => {
    //       console.log('uid', this.uid);
    //       res.forEach(e => {
    //         e.start = (<Timestamp>e.start).toDate();
    //         e.end = (<Timestamp>e.end).toDate();
    //       })
    //       return res.map(element => {
    //         const notification = <Notification>{
    //           text: element.title,
    //           time: 'just now',
    //           icon: 'plus_one',
    //           color: 'primary',
    //         };
    //         return notification;
    //       });
    //     }));
    //     break;
    //   case 'user':
    //     this.notifications$ = this.stream(ref => ref.orderBy("start").startAfter(startDate)
    //       // .where('isApproved', '==', false)
    //       .where('requestUserId', '==', localStorage.getItem('uid'))
    //     ).pipe(map((res: any) => {
    //       console.log('uid', this.uid);
    //       res.forEach(e => {
    //         e.start = (<Timestamp>e.start).toDate();
    //         e.end = (<Timestamp>e.end).toDate();
    //       })
    //       return res.map(element => {
    //         const notification = <Notification>{
    //           text: element.title,
    //           time: 'just now',
    //           icon: 'plus_one',
    //           color: 'primary',
    //         };
    //         return notification;
    //       });
    //     }));
    //     break;
    //   default:
    //     this.notifications$ = null;
    //     break;
    // }

    // this.notifications$ = this.stream(ref => ref.where('isApproved', '==', false).where('requestUserId', '==', this.me)).pipe(map((res: any) => {

    // // this.notifications$.subscribe(data => {
    // //   this.notifications = data;
    // // })

  }

  ngOnInit(): void {
    console.log('me', this.me);

  }

  ngAfterViewInit(): void {

  }

  public getDbNotifications() {
    return this.notifications$;
  }

  public getNotifications(): object[] {
    return [
      {
        text: 'You have 3 new orders.',
        time: 'just now',
        icon: 'plus_one',
        color: 'primary',
      }, {
        text: 'Database error',
        time: '1 min',
        icon: 'error_outline',
        color: 'secondary',
      }, {
        text: 'The Death Star is built!',
        time: '2 hours',
        icon: 'new_releases',
        color: 'primary',
      }, {
        text: 'You have 4 new mails.',
        time: '5 days',
        icon: 'mail_outline',
        color: 'primary',
      },
    ];
  }
}
