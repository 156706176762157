import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, } from '@angular/router';

//import { AuthService } from '@services/*';
import { AuthService, User } from '../../connect'
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { filter, map, tap, take, switchMap, takeUntil, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, merge, of } from 'rxjs';
import { UserProfile } from 'app/connect/auth/user-profile.service';
// import undefined = require('firebase/empty-import');
import { Location } from '@angular/common';
import { UpgradableComponent } from 'theme/components/upgradable';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})
export class CommonLayoutComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;

  // public user;
  // readonly user: Observable<any>;
  private sub: Subscription;



  /** Returns the current authenticated userId or 'unknown' */
  get me(): string { return this.guard.userId || 'unknown'; }
  /** Returns the current authenticated userId or 'unknown' */
  get meObject(): any { return this.guard.user || 'unknown'; }
  /** Returns true whenever the current user is authenticated */
  // get isAauthenticated(): boolean { console.log('authenticated', this.guard.authenticated); return this.guard.authenticated; }
  get user(): any { return this.guard.user; }

  get auth() { return this.profile.auth; }

  /** Returns the user id */
  get uid(): string { return !!this.user && this.user.uid || ''; }

  // isAauthenticated: any;

  constructor(public guard: AuthGuard, private profile: UserProfile, private router: Router,
    private location: Location) {

    super();
    // Streams the user name from the profile
    // this.user = this.profile.stream().pipe(
    //   map(data => !!data ? data : undefined)
    // );

    this.auth.authState$.subscribe(x => {
      localStorage.setItem('uid', this.uid);
    });
  }

  public ngOnInit() {
    // if(this.au)
    // this.authService.userData.subscribe(user => this.user = user ? user : {
    //   username: 'Luke',
    //   email: 'Luke@skywalker.com',
    // });

    // // Acts immediately whenever the user is already logged-in
    // this.user = (u: User) => {
    //   console.log('user', this.user);
    //   if (this.authenticated) { return this.meObject; }

    //   // Proceed to authenticate the user otherwise
    //   else {
    //     this.guard.authenticate().then(user => {

    //       // Stops on authentication failed/aborted
    //       if (!user) { return false; }
    //       // Checks the user against the likers
    //       console.log('user', user);
    //       return user;
    //     });
    //   }
    // }
    //console.log('authenticated', this.guard.authenticated);
    //this.isAauthenticated = this.guard.authenticated;
    this.sub = this.auth.authState$.pipe(filter(user => !user))
      .subscribe(() => {
        this.router.navigate(['/app/home/']);
        componentHandler.upgradeDom();
      });
  }

  // Disposes of the observable subscriptions
  ngOnDestroy() { !!this.sub && this.sub.unsubscribe(); }

  public logout() {
    // this.authService.logout()
    //   .subscribe(res => this.router.navigate(['/pages/login']));
    //this.authService.signOut()
    //  .then(res => this.router.navigate(['/pages/login']));
    //this.auth.disconnect();
    this.guard.disconnect();
  }

  public settings() {
    // this.router.navigate(['/pages/settings']);
    this.router.navigate(['settings']);
  }

  private schedules() {
    this.router.navigate([`/app/schedules/${this.uid}`]);
  }

  back() {
    this.location.back();
  }

}

declare var componentHandler: MaterialDesignLite.ComponentHandler;