import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ThemeModule } from 'theme';
import { GdprComponent } from './gdpr.component';

// Define navigation routes
// const routes: Routes = [

//   { path: '', component: CommonLayoutComponent }

// ];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    RouterModule,
  ],
  declarations: [
    GdprComponent
  ],
})
export class GdprModule { }