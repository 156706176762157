import { Component, HostBinding, OnInit, AfterViewInit } from '@angular/core';

import { NotificationMenuService, } from './notification-menu.service';
import { Notification } from './notification.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthGuard } from 'app/connect/auth/auth-guard.service';
import { GlobalService } from 'app/connect/auth/global.service';

@Component({
  selector: 'app-notification-menu',
  styleUrls: ['./notification-menu.component.scss'],
  templateUrl: './notification-menu.component.html',
  providers: [NotificationMenuService],
})
export class NotificationMenuComponent implements OnInit, AfterViewInit {
  @HostBinding('class.notification-menu') private readonly notificationMenu = true;

  // private notifications$: Observable<Notification[]>;
  public notifications$;//: Observable<Notification[]>;
  /** Returns true whenever the current user is authenticated */
  get authenticated(): boolean { return this.guard.authenticated; }
  /** Returns the current authenticated userId or 'unknown' */
  get me(): string { return this.guard.userId || 'unknown'; }

  constructor(private notificationMenuService: NotificationMenuService, private guard: AuthGuard, private globalSrv: GlobalService) {


    // this.notifications$ = this.notificationMenuService.getNotifications();


    globalSrv.userIdValue.subscribe((nextValue) => {
      alert(nextValue);  // this will happen on every change


      // this.notificationMenuService.notifications$.pipe().subscribe((res: Notification[]) => {
      //   console.log('notifications', res);
      //   this.notifications$ = res;
      // });
    })
  }

  ngOnInit() {

    this.notificationMenuService.getDbNotifications().pipe().subscribe((res: Notification[]) => {
      console.log('notifications', res);
      this.notifications$ = res;
    });
  }

  ngAfterViewInit() {
    // this.notifications$ = this.notificationMenuService.getNotifications().pipe(
    //   map(res => {
    //     return res;
    //   }));
  }



}
