import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ThemeModule } from 'theme';
import { ManageUsersComponent } from './manage-users.component';
import {
    MatCardModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
} from '@angular/material';
import { UserFormComponent } from './user-form';
import { FormsModule as NgFormsModule } from '@angular/forms';
import { MaterialAngularSelectModule } from 'material-angular-select';
import { UploadComponent } from './user-form/image-upload';
import { RecordsComponent } from './user-form/records/records.component';

// Define navigation routes
// const routes: Routes = [

//   { path: 'places', component: PlacesComponent }

// ];

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        NgFormsModule,
        MaterialAngularSelectModule,
        FlexLayoutModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatListModule,
        MatProgressBarModule,

        // RouterModule.forChild(routes),
    ],
    declarations: [
        ManageUsersComponent,
        UserFormComponent,
        UploadComponent,
        RecordsComponent
    ],
    providers: [],
    // exports: [ RouterModule ]
})
export class ManageUsersModule { }